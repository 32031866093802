export default [
  {
    path: 'characteristic-fields',
    name: 'auth.characteristic_field.list',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/shop/CharacteristicFieldList')
  },
  {
    path: 'characteristic-field/:id(\\d+)',
    name: 'auth.characteristic_field.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/shop/UpdateCharacteristicField')
  },
  {
    path: 'characteristic-field/create',
    name: 'auth.characteristic_field.create',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/shop/CreateCharacteristicField')
  }
]
