<template>
  <router-link :to="translatedTo">
    <slot />
  </router-link>
</template>

<script>
import { USER_LANGUAGE } from '@/store/types.getters'

export default {
  name: 'TRouterLink',
  props: {
    to: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    translatedTo () {
      const userLanguage = this.$store.getters[USER_LANGUAGE]
      const translatedTo = Object.assign({}, this.to)
      translatedTo.name = this.sanitizeRouteName(translatedTo.name)

      if (userLanguage !== 'ro') {
        translatedTo.name = userLanguage + '.' + translatedTo.name
      }

      return translatedTo
    }
  }
}
</script>
