export default [
  {
    path: 'orders',
    name: 'order.history',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '../views/order/OrdersHistory')
  },
  {
    path: 'order/:id(\\d+)',
    name: 'order.view',
    meta: { requiresAuth: true },
    props: true,
    component: () => import(/* webpackChunkName: "user" */ '../views/order/Order')
  },
  {
    path: 'cart',
    name: 'order.cart',
    component: () => import(/* webpackChunkName: "shop" */ '../views/order/Cart')
  },
  {
    path: 'cart/bank-processing',
    name: 'order.bank_processing',
    component: () => import(/* webpackChunkName: "shop" */ '../views/order/BankProcessing')
  },
  {
    path: 'cart/final',
    name: 'order.cart_final',
    component: () => import(/* webpackChunkName: "shop" */ '../views/order/CartFinal')
  },
  {
    path: 'order/:deliveryRouteExternalId/tracking',
    name: 'order.tracking',
    props: true,
    component: () => import(/* webpackChunkName: "order-tracking" */ '../views/order/OrderTracking')
  },
  {
    path: 'delivery-address/create',
    name: 'order.create_delivery_address',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '../views/order/CreateDeliveryAddress')
  },
  {
    path: 'delivery-address/:id(\\d+)/change',
    name: 'order.change_delivery_address',
    meta: { requiresAuth: true },
    props: true,
    component: () => import(/* webpackChunkName: "user" */ '../views/order/ChangeDeliveryAddress')
  }
]
