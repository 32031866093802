import { USER_LANGUAGE } from '@/store/types.getters'

export default {
  methods: {
    redirect (location) {
      const translatedLocation = Object.assign({}, location)
      translatedLocation.name = this.sanitizeRouteName(translatedLocation.name)

      const language = this.$store.getters[USER_LANGUAGE]

      if (language !== 'ro') {
        translatedLocation.name = language + '.' + translatedLocation.name
      }

      return this.$router.push(translatedLocation)
        .catch(error => {
          if (typeof error === 'undefined' || error.name !== 'NavigationDuplicated') {
            throw error
          }
        })
    },
    redirectToHome () {
      return this.redirect({ name: 'home' })
    },
    simpleChangeLocaleRedirect () {
      return this.redirect({ name: this.sanitizedRouteName })
    },
    sanitizeRouteName (routeName) {
      if (routeName.startsWith('ru.') || routeName.startsWith('en.')) {
        return routeName.substr(3)
      }

      return routeName
    }
  },
  computed: {
    sanitizedRouteName () {
      return this.sanitizeRouteName(this.$route.name)
    }
  }
}
