export default [
  {
    path: 'carousels',
    name: 'auth.carousel.list',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/CarouselList')
  },
  {
    path: 'carousel/:id(\\d+)',
    name: 'auth.carousel.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/UpdateCarousel')
  },
  {
    path: 'carousel/create',
    name: 'auth.carousel.create',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/CreateCarousel')
  }
]
