export const AUTH_SET_TOKENS = 'AUTH_SET_TOKENS'
export const AUTH_RESET = 'AUTH_RESET'
export const AUTH_SET_ROLES = 'AUTH_SET_ROLES'

export const GENERAL_CHANGE_LOADER = 'GENERAL_CHANGE_LOADER'
export const GENERAL_SET_BANNERS = 'GENERAL_SET_BANNERS'
export const GENERAL_SET_META = 'GENERAL_SET_META'
export const GENERAL_SET_CITIES = 'GENERAL_SET_CITIES'
export const GENERAL_SET_CURRENT_CITY_ID = 'GENERAL_SET_CURRENT_CITY_ID'
export const GENERAL_SET_FORCE_HIDE_FREE_BOTTLE_BANNER = 'GENERAL_SET_FORCE_HIDE_FREE_BOTTLE_BANNER'

export const GENERAL_SHOW_POPUP = 'SHOW_POPUP'

export const USER_SET_PROFILE = 'USER_SET_PROFILE'
export const USER_SET_LANGUAGE = 'USER_SET_LANGUAGE'
export const USER_SET_CITY_ID = 'USER_SET_CITY_ID'

export const SHOP_SET_CATEGORIES = 'SHOP_SET_CATEGORIES'
export const SHOP_SET_CATEGORY = 'SHOP_SET_CATEGORY'
export const SHOP_SET_CATEGORY_FILTERS = 'SHOP_SET_CATEGORY_FILTERS'
export const SHOP_SET_PRODUCT = 'SHOP_SET_PRODUCT'
export const SHOP_SET_LAST_SEEN_PRODUCTS = 'SHOP_SET_LAST_SEEN_PRODUCTS'
export const SHOP_SET_SEARCH_QUERY = 'SHOP_SET_SEARCH_QUERY'

export const ORDER_SET_DRAFT = 'ORDER_SET_DRAFT'
export const ORDER_SET_DRAFT_TOKEN = 'ORDER_SET_DRAFT_TOKEN'
export const ORDER_SET_DELIVERY_ADDRESSES = 'ORDER_SET_DELIVERY_ADDRESSES'
export const ORDER_SET_ORDERS = 'ORDER_SET_ORDERS'
