import {getLoaderRequest, getRequest, postLoaderRequest} from '@/services/requests'

export const staticPage = getLoaderRequest('/v1/static-page')
export const carousels = getLoaderRequest('/v1/carousels')
export const feedbacks = getLoaderRequest('/v1/feedbacks')
export const addFeedback = postLoaderRequest('/v1/feedback/create')
export const banners = getLoaderRequest('/v1/banners')
export const newsList = getLoaderRequest('/v1/news')
export const news = getLoaderRequest('/v1/news/:slug')
export const contactUs = postLoaderRequest('/v1/contact-us')
export const faq = getLoaderRequest('/v1/questions-answers')
export const meta = getLoaderRequest('/v1/static-pages/meta-info')
export const cities = getLoaderRequest('/v1/cities')
export const sendOffer = postLoaderRequest('/v1/offer/create')

export const popups = getRequest('/v1/popups')
