import Vue from 'vue'
import router from '../router'
import VueGtm from 'vue-gtm'

Vue.use(VueGtm, {
  id: 'GTM-NK4BNBL',
  enabled: process.env.VUE_APP_IS_PROD_SERVER === 'True',
  debug: false,
  vueRouter: router
})
