import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    sm: 600,
    md: 768,
    lg: 1171,
    xl: Infinity
  }
})
