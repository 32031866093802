export default [
  {
    path: 'profile',
    name: 'user.edit_profile',
    meta: { requiresAuth: true },
    props: true,
    component: () => import(/* webpackChunkName: "user" */ '../views/user/EditProfilePage')
  },
  {
    path: 'change-password',
    name: 'user.change_password',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '../views/user/ChangePassword')
  },
  {
    path: 'favorite-products',
    name: 'user.favorite_products',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '../views/user/FavoriteProducts')
  }
]
