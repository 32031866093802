import * as api from '@/api/user'
import * as actionTypes from '@/store/types.actions'
import {
  USER_SET_LANGUAGE,
  USER_SET_PROFILE,
  USER_SET_CITY_ID,
  GENERAL_SET_CURRENT_CITY_ID
} from '@/store/types.mutations'
import { USER_LANGUAGE, USER_PROFILE, GENERAL_CURRENT_CITY_ID } from '@/store/types.getters'
import { DEFAULT_LANGUAGE, loadLanguageAsync, SUPPORTED_LANGUAGES } from '@/setup/i18n'

const state = {
  profile: null,
  language: DEFAULT_LANGUAGE
}

const getters = {
  [USER_PROFILE]: state => state.profile,
  [USER_LANGUAGE]: state => state.language
}

const actions = {
  [actionTypes.USER_FETCH_PROFILE] ({ commit, dispatch }, payload) {
    return api.profile(payload)
      .then(({ data }) => {
        commit(USER_SET_PROFILE, data)
        commit(GENERAL_SET_CURRENT_CITY_ID, data.cityId)

        dispatch(actionTypes.USER_CHANGE_LANGUAGE, data.language)
      })
  },
  [actionTypes.USER_CHANGE_LANGUAGE] ({ commit, getters }, language) {
    if (!SUPPORTED_LANGUAGES.includes(language)) {
      language = DEFAULT_LANGUAGE
    }

    loadLanguageAsync(language)

    if (getters[USER_PROFILE]) {
      api.changeLanguage({ language: language })
    }

    return commit(USER_SET_LANGUAGE, language)
  },
  [actionTypes.USER_FETCH_FAVORITE_PRODUCTS] ({ getters }) {
    return api.favoriteProducts({ city_id: getters[GENERAL_CURRENT_CITY_ID] })
  },
  [actionTypes.USER_UPDATE_PROFILE] ({ dispatch }, payload) {
    return api.updateProfile(payload)
      .then(() => dispatch(actionTypes.USER_FETCH_PROFILE))
  },
  [actionTypes.USER_UPDATE_CITY] ({ commit }, cityId) {
    return api.updateUserCity({ cityId: cityId })
      .then(() => commit(USER_SET_CITY_ID, cityId))
  }
}

const mutations = {
  [USER_SET_PROFILE] (state, profile) {
    state.profile = profile
  },
  [USER_SET_LANGUAGE] (state, language) {
    state.language = language
  },
  [USER_SET_CITY_ID] (state, cityId) {
    state.profile.cityId = cityId
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
