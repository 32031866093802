<template>
  <fragment>
    <t-router-link :to="generateProductRoute(product)">
      <slot>{{ product.name|st }}</slot>
    </t-router-link>
  </fragment>
</template>

<script>
export default {
  name: 'ProductLink',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    generateProductRoute (product) {
      const mainCategory = product.mainCategory
      let categorySlug = null
      categorySlug = this.$options.filters.st(mainCategory)
      // Initialize the category slug
      const route = {
        name: 'shop.product',
        params: {
          productSlug: this.$options.filters.st(product.slug)
        }
      }

      if (categorySlug) {
        route.params.categorySlug = categorySlug
      }
      return route
    }
  }
}
</script>

<style scoped>

</style>
