<template>
  <div class="top-slider" v-if="initialized">
    <Swiper
      :modules="modules"
      :navigation="true"
      :pagination="{ clickable: true }"
      :loop="true"
      :initialSlide="0"
    >
      <SwiperSlide v-for="(slide, index) in carouselSlides" :key="index">
        <a :href="$options.filters.st(slide.link)" class="image-link">
          <img :src="getImageByDeviceTypeAndLocale(slide.images)" alt="Slide"/>
          <div class="slide-layer">
            <div class="offer">{{ $t('special_offer') }}</div>
            <div class="title">{{ getTextByDeviceType(slide.textDesktop, slide.textMobile, slide.textTablet)|st }}</div>
            <div class="text" />
          </div>
        </a>
      </SwiperSlide>

      <div slot="pagination" class="swiper-pagination" />
      <div slot="button-prev" class="swiper-button-prev" />
      <div slot="button-next" class="swiper-button-next" />
    </Swiper>
  </div>

</template>

<script>
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/swiper-vue.mjs'
import 'swiper/swiper.css'

import * as generalApi from '@/api/general'
import { USER_LANGUAGE } from "@/store/types.getters"

export default {
  name: 'Carousel',
  components: {
    Swiper,
    SwiperSlide
  },
  setup () {
    return {
      modules: [Navigation, Pagination]
    }
  },

  data () {
    return {
      carouselSlides: [],
      initialized: false,
      carouselOptions: {
        loop: true,
        navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
        pagination: { el: '.swiper-pagination', clickable: true }
      }
    }
  },
  created () {
    generalApi.carousels()
      .then(({ data }) => {
        this.carouselSlides = data._embedded.items
        this.$nextTick(() => {
          this.initialized = true
        })
      })
  },
  watch: {
    '$store.getters.USER_LANGUAGE': 'simpleChangeLocaleRedirect'
  },
  methods: {
    getImageByDeviceTypeAndLocale (images) {
      if (!images) {
        return ''
      }

      const language = this.$store.getters[USER_LANGUAGE]
      const screenWidth = window.innerWidth
      let imageType = 'DESKTOP'

      if (screenWidth <= 768) {
        imageType = 'MOBILE'
      } else if (screenWidth > 768 && screenWidth <= 1024) {
        imageType = 'TABLET'
      }

      const image = images.find(img => img.type === imageType && img.locale === language) ||
        images.find(img => img.type === 'DESKTOP' && img.locale === language) ||
        images.find(img => img.type === 'DESKTOP')

      return image ? image.absolutePath : ''
    },
    getTextByDeviceType (textDesktop, textMobile, textTablet) {
      const screenWidth = window.innerWidth
      let text = textDesktop

      if (screenWidth <= 768) {
        text = textMobile
      } else if (screenWidth > 768 && screenWidth <= 1024) {
        text = textTablet
      }

      return text
    }
  }
}
</script>
<style>
.swiper {
  height: 500px;
}

.image-link {
  display: block;
  text-decoration: none
}
.swiper-pagination{
  margin-left: 28%;
}

.swiper-pagination-bullet {
  margin-left: 5px;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .swiper-pagination{
    margin-left: 38%;
  }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  .swiper-pagination{
    margin-left: 33%;
  }

  .swiper {
    height: 375px;
  }
}

.image-link {
  height: inherit;
}

.swiper-slide img {
  height: 100% !important;
}
</style>
