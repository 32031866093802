<template>
  <div class="popular-produscts">
    <div class="items-head">
      {{ $t('popular_products') }}

      <t-router-link :to="{ name: 'shop.all' }">{{ $t('button.see_all') }}</t-router-link>
    </div>

    <div class="products-box">
      <div v-if="productOfDay" class="product-day">
        <div class="title">{{ $t('product.product_of_day.title') }}</div>
        <div class="desc">{{ $t('product.product_of_day.description') }}</div>

        <img :src="productOfDay.coverImage.absolutePath" style="height: 210px;">

        <div class="product-name">{{ productOfDay.name|st }}</div>
        <div class="product-price">{{ productOfDay.price }} {{ $t('currency.lei') }}</div>

        <div class="nav-bottom">
          <a class="cart" href="#" @click.prevent="addProductToOrder({ product: productOfDay, quantity: 1 })" />

          <ProductLink class="about-product" :product="productOfDay">{{ $t('button.product_details') }}</ProductLink>
        </div>
      </div>

      <div class="products-item">
        <ProductPreview v-for="product in popularProducts" :key="product.id" :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductPreview from '@/components/shop/product/ProductPreview'
import { mapActions } from 'vuex'
import { SHOP_FETCH_PRODUCT_OF_DAY, SHOP_FETCH_PRODUCTS, ORDER_ADD_PRODUCT } from '@/store/types.actions'

export default {
  name: 'PopularProducts',
  components: { ProductPreview },
  data () {
    return {
      productOfDay: null,
      popularProducts: []
    }
  },
  created () {
    this.$store.dispatch(SHOP_FETCH_PRODUCT_OF_DAY)
      .then(({ data }) => { this.productOfDay = data })

    this.$store.dispatch(SHOP_FETCH_PRODUCTS, { order_by: '-popularity', per_page: 6 })
      .then(({ data }) => { this.popularProducts = data._embedded.items })
  },
  methods: {
    ...mapActions({
      addProductToOrder: ORDER_ADD_PRODUCT
    })
  }
}
</script>
