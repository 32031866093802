import * as api from '@/api/order'
import * as actionTypes from '../types.actions'
import * as mutationTypes from '../types.mutations'
import * as getterTypes from '../types.getters'

const state = {
  draftOrderToken: null,
  draftOrder: null,
  deliveryAddresses: [],
  orders: []
}

const getters = {
  [getterTypes.ORDER_DRAFT_TOKEN]: state => state.draftOrderToken,
  [getterTypes.ORDER_DRAFT]: state => state.draftOrder,
  [getterTypes.ORDER_DELIVERY_ADDRESSES]: state => state.deliveryAddresses,
  [getterTypes.ORDER_ORDERS]: state => state.orders
}

const actions = {
  [actionTypes.ORDER_ADD_PRODUCT] ({ dispatch, getters, commit, state }, { product, quantity }) {
    return api.addProductToOrder({ quantity: quantity, token: state.draftOrderToken }, product.id)
      .then(({ data }) => {
        if (!getters[getterTypes.AUTH_IS_LOGGED_IN]) {
          commit(mutationTypes.ORDER_SET_DRAFT_TOKEN, data.token)
        }

        dispatch(actionTypes.ORDER_FETCH_DRAFT)
      })
  },
  [actionTypes.ORDER_REMOVE_PRODUCT] ({ dispatch, state }, product) {
    return api.deleteProductFromOrder({ token: state.draftOrderToken }, product.id)
      .then(() => dispatch(actionTypes.ORDER_FETCH_DRAFT))
  },
  [actionTypes.ORDER_FETCH_DRAFT] ({ commit, state, rootGetters }, payload) {
    if (!rootGetters[getterTypes.AUTH_IS_LOGGED_IN] && !state.draftOrderToken) {
      return Promise.resolve()
    }

    return api.draftOrder(
      { token: state.draftOrderToken },
      null,
      null,
      payload && payload.disableLoader ? {} : { loader: true }
    )
      .then(({ data }) => commit(mutationTypes.ORDER_SET_DRAFT, data))
      .catch(() => commit(mutationTypes.ORDER_SET_DRAFT, null))
      .catch(() => commit(mutationTypes.ORDER_SET_DRAFT_TOKEN, null))
  },
  [actionTypes.ORDER_UPDATE_PRODUCT] ({ dispatch, state }, { product, quantity }) {
    return api.updateProductFromOrder({ quantity: quantity, token: state.draftOrderToken }, product.id)
      .then(() => dispatch(actionTypes.ORDER_FETCH_DRAFT, { disableLoader: true }))
  },
  [actionTypes.ORDER_UPDATE_DELIVERY] ({ state }, form) {
    const payload = Object.assign({}, form, { token: state.draftOrderToken })

    return api.updateDeliveryInfo(payload)
  },
  [actionTypes.ORDER_VALIDATE_ITEMS] ({ state }) {
    return api.validateOrderItems({ token: state.draftOrderToken })
  },
  [actionTypes.ORDER_UPDATE_PAYMENT] ({ commit, state }, form) {
    const payload = Object.assign({}, form, { token: state.draftOrderToken })

    return api.updatePaymentInfo(payload)
      .then(({ data }) => {
        commit(mutationTypes.ORDER_SET_DRAFT, null)
        commit(mutationTypes.ORDER_SET_DRAFT_TOKEN, null)
        commit(mutationTypes.GENERAL_SET_FORCE_HIDE_FREE_BOTTLE_BANNER, true)

        return data
      })
  },
  [actionTypes.ORDER_LINK_DRAFT] ({ dispatch, commit, state }) {
    return api.linkDraftOrder({ token: state.draftOrderToken })
      .then(() => commit(mutationTypes.ORDER_SET_DRAFT_TOKEN, null))
      .then(() => dispatch(actionTypes.ORDER_FETCH_DRAFT))
  },
  [actionTypes.ORDER_FETCH_DELIVERY_ADDRESSES] ({ commit, rootGetters }) {
    if (!rootGetters[getterTypes.AUTH_IS_LOGGED_IN]) {
      return Promise.resolve()
    }

    return api.deliveryAddresses()
      .then(({ data }) => commit(mutationTypes.ORDER_SET_DELIVERY_ADDRESSES, data._embedded.items))
  },
  [actionTypes.ORDER_FETCH_ORDERS] ({ commit, getters }, payload) {
    return api.orders({ ...payload, city_id: getters[getterTypes.GENERAL_CURRENT_CITY_ID] })
      .then(({ data }) => {
        commit(mutationTypes.ORDER_SET_ORDERS, data._embedded.items)

        return data
      })
  }
}

const mutations = {
  [mutationTypes.ORDER_SET_DRAFT] (state, draftOrder) {
    state.draftOrder = draftOrder
  },
  [mutationTypes.ORDER_SET_DRAFT_TOKEN] (state, draftOrderToken) {
    state.draftOrderToken = draftOrderToken
  },
  [mutationTypes.ORDER_SET_DELIVERY_ADDRESSES] (state, deliveryAddresses) {
    state.deliveryAddresses = deliveryAddresses
  },
  [mutationTypes.ORDER_SET_ORDERS] (state, orders) {
    state.orders = orders
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
