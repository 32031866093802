export const DELIVERY_TIME_SETS = {
  1: {
    workweek: [
      '8:00-13:00',
      '13:00-17:00',
      '17:00-19:00'
    ],
    weekend: [
      '8:00-15:00'
    ]
  },
  2: {
    workweek: [
      '8:00-15:00',
      '17:00-19:00'
    ],
    weekend: [
      '8:00-15:00'
    ]
  }
}

export const BOTTLES_CATEGORY_SLUG = 'bottles'
