<template>
  <fragment>
    <router-view />

    <vue-element-loading :active="isActiveLoader" is-full-screen />
  </fragment>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import { mapGetters } from 'vuex'
import store from '@/store'
import { GENERAL_IS_ACTIVE_LOADER, AUTH_IS_LOGGED_IN, GENERAL_META_BY_KEY } from '@/store/types.getters'
import {GENERAL_FETCH_META, USER_FETCH_PROFILE, GENERAL_FETCH_CITIES} from '@/store/types.actions'

export default {
  name: 'CommonLayout',
  components: { VueElementLoading },
  async beforeRouteEnter (to, from, next) {
    const promises = []

    promises.push(store.dispatch(GENERAL_FETCH_CITIES))

    if (store.getters[AUTH_IS_LOGGED_IN]) {
      promises.push(store.dispatch(USER_FETCH_PROFILE))
    }

    await Promise.all(promises)

    next()
  },
  computed: {
    ...mapGetters({
      isActiveLoader: GENERAL_IS_ACTIVE_LOADER,
      metaByKey: GENERAL_META_BY_KEY
    })
  },
  async created () {
    await this.$store.dispatch(GENERAL_FETCH_META)
  },
  metaInfo () {
    const meta = this.metaByKey(this.sanitizedRouteName)

    if (!meta) return {}

    return {
      title: this.$options.filters.st(meta.metaTitle),
      meta: [
        { vmid: 'description', name: 'description', content: this.$options.filters.st(meta.metaDescription) },
        { vmid: 'og:title', property: 'og:title', content: this.$options.filters.st(meta.ogTitle) },
        { vmid: 'og:description', property: 'og:description', content: this.$options.filters.st(meta.ogDescription) },
        { vmid: 'og:image', property: 'og:image', content: meta.ogImage ? meta.ogImage.absolutePath : null }
      ]
    }
  }
}
</script>
