<template>
  <div v-if="product" class="overlay-product open">
    <div class="product-add-to-cart">
      <a class="close" href="#" @click.prevent="close" />

      <div class="product-images">
        <img :src="product.coverImage.absolutePath">
      </div>

      <template v-if="product.inStock && !product.hidden">
        <div class="count-product">
          <span class="remove" @click="quantity = Math.max(parseInt(quantity) - 1, 1)" />
          <input v-model="quantity" type="text">
          <div class="add-product" @click="quantity = parseInt(quantity) + 1" />
        </div>

        <div class="total-amount">{{ $t('product.total_amount') }} <span>{{ quantity * product.price }} {{ $t('currency.lei') }}</span></div>

        <button class="btn-yellow add-to-cart" href="#" @click.prevent="add">
          {{ $t('button.add_to_cart') }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { mapActions } from 'vuex'
import { ORDER_ADD_PRODUCT } from '@/store/types.actions'

export default {
  name: 'AddToCardMobile',
  data () {
    return {
      product: null,
      quantity: 1
    }
  },
  created () {
    this.$root.$on('add-to-card-mobile:show', this.showProduct)
  },
  beforeDestroy () {
    this.$root.$off('add-to-card-mobile:show', this.showProduct)
  },
  methods: {
    ...mapActions({
      addProductToOrder: ORDER_ADD_PRODUCT
    }),
    showProduct (product) {
      this.product = product

      disableBodyScroll(this.$root.$el)
    },
    add () {
      this.addProductToOrder({ product: this.product, quantity: this.quantity })
      this.close()
    },
    close () {
      this.product = null
      this.quantity = 1

      enableBodyScroll(this.$root.$el)
    }
  }
}
</script>

<style scoped>
  button.btn-yellow {
    border: 0;
  }
</style>
