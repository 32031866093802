<template>
  <div v-if="showModal" class="overlay-location open">
    <div v-on-clickaway="close" class="inner-location">
      <a class="close" href="#" @click.prevent="close" />

      <div class="location-image">
        <img src="../../assets/img/location-map.webp">
      </div>

      <h2 class="title">{{ $t('home.city_selector.header') }}</h2>

      <div class="desc">{{ $t('home.city_selector.description') }}</div>

      <select v-model="currentCityId" class="locations-list">
        <option v-for="city in cities" :key="city.id" :value="city.id">
          {{ city.name|st }}
        </option>
      </select>

      <a class="btn-yellow choose-location" href="#" @click.prevent="save">{{ $t('button.save_selection') }}</a>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { mixin as clickaway } from 'vue-clickaway'
import { GENERAL_CITIES, GENERAL_CURRENT_CITY, USER_PROFILE } from '@/store/types.getters'
import { GENERAL_CHANGE_CURRENT_CITY } from '@/store/types.actions'

export default {
  name: 'CitySelectorModal',
  mixins: [clickaway],
  data () {
    return {
      showModal: false,
      currentCityId: null
    }
  },
  computed: {
    cities () {
      return sortBy(this.$store.getters[GENERAL_CITIES], (city) => this.$options.filters.st(city.name))
    }
  },
  created () {
    this.$root.$on('city-selector-modal:open', this.openModal)
  },
  mounted () {
    if (this.$route.params.showCitySelectorModal) {
      const loop = setInterval(() => {
        const currentCity = this.$store.getters[GENERAL_CURRENT_CITY]
        const userProfile = this.$store.getters[USER_PROFILE]

        if (currentCity && userProfile) {
          clearInterval(loop)
          this.openModal()
        }
      }, 1000)
    }
  },
  beforeDestroy () {
    this.$root.$off('city-selector-modal:open', this.openModal)
  },
  methods: {
    openModal () {
      this.currentCityId = this.$store.getters[GENERAL_CURRENT_CITY].id

      this.showModal = true

      disableBodyScroll(this.$root.$el)
    },
    async save () {
      await this.$store.dispatch(GENERAL_CHANGE_CURRENT_CITY, this.currentCityId)

      this.close()
    },
    close () {
      enableBodyScroll(this.$root.$el)

      this.showModal = false
    }
  }
}
</script>
