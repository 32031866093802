<template>
  <div class="new-products">
    <div class="items-head">
      {{ $t('product.new_products') }}

      <t-router-link :to="{ name: 'shop.all', query: { orderBy: '-creationDate' } }">{{ $t('button.see_all') }}</t-router-link>
    </div>

    <div class="new-items-box">
      <ProductPreview v-for="product in newProducts" :key="product.id" :product="product" />
    </div>
  </div>
</template>

<script>
import ProductPreview from '@/components/shop/product/ProductPreview'
import { SHOP_FETCH_PRODUCTS } from '@/store/types.actions'

export default {
  name: 'NewProducts',
  components: { ProductPreview },
  data () {
    return {
      newProducts: []
    }
  },
  created () {
    this.$store.dispatch(SHOP_FETCH_PRODUCTS, { order_by: '-creationDate', per_page: 5 })
      .then(({ data }) => { this.newProducts = data._embedded.items })
  }
}
</script>
