export default [
  {
    path: 'static-pages',
    name: 'auth.static_page.list',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/StaticPageList')
  },
  {
    path: 'static-page/:id(\\d+)',
    name: 'auth.static_page.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/UpdateStaticPage')
  }
]
