export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_AUTHENTICATE = 'AUTH_AUTHENTICATE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_CONFIRM_EMAIL = 'AUTH_CONFIRM_EMAIL'
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD'
export const AUTH_GOOGLE = 'AUTH_GOOGLE'
export const AUTH_FACEBOOK = 'AUTH_FACEBOOK'
export const AUTH_VK = 'AUTH_VK'

export const GENERAL_INCREASE_LOADER = 'GENERAL_INCREASE_LOADER'
export const GENERAL_DECREASE_LOADER = 'GENERAL_DECREASE_LOADER'
export const GENERAL_FETCH_BANNERS = 'GENERAL_FETCH_BANNERS'
export const GENERAL_FETCH_META = 'GENERAL_FETCH_META'
export const GENERAL_FETCH_CITIES = 'GENERAL_FETCH_CITIES'
export const GENERAL_CHANGE_CURRENT_CITY = 'GENERAL_CHANGE_CURRENT_CITY'

export const GENERAL_SHOW_POPUP = 'GENERAL_SHOW_POPUP'


export const USER_FETCH_PROFILE = 'USER_FETCH_PROFILE'
export const USER_CHANGE_LANGUAGE = 'USER_CHANGE_LANGUAGE'
export const USER_FETCH_FAVORITE_PRODUCTS = 'USER_FETCH_FAVORITE_PRODUCTS'
export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE'
export const USER_UPDATE_CITY = 'USER_UPDATE_CITY'

export const SHOP_FETCH_CATEGORIES = 'SHOP_FETCH_CATEGORIES'
export const SHOP_FETCH_CATEGORY = 'SHOP_FETCH_CATEGORY'
export const SHOP_FETCH_CATEGORY_FILTERS = 'SHOP_FETCH_CATEGORY_FILTERS'
export const SHOP_FETCH_PRODUCT_OF_DAY = 'SHOP_FETCH_PRODUCT_OF_DAY'
export const SHOP_FETCH_PRODUCTS = 'SHOP_FETCH_PRODUCTS'
export const SHOP_FETCH_PRODUCT = 'SHOP_FETCH_PRODUCT'
export const SHOP_FETCH_LAST_SEEN_PRODUCTS = 'SHOP_FETCH_LAST_SEEN_PRODUCTS'
export const SHOP_FETCH_RELATED_PRODUCTS = 'SHOP_FETCH_RELATED_PRODUCTS'
export const SHOP_TOGGLE_PRODUCT_FAVORITE = 'SHOP_TOGGLE_PRODUCT_FAVORITE'

export const ORDER_ADD_PRODUCT = 'ORDER_ADD_PRODUCT'
export const ORDER_REMOVE_PRODUCT = 'ORDER_REMOVE_PRODUCT'
export const ORDER_FETCH_DRAFT = 'ORDER_FETCH_DRAFT'
export const ORDER_UPDATE_PRODUCT = 'ORDER_UPDATE_PRODUCT'
export const ORDER_VALIDATE_ITEMS = 'ORDER_VALIDATE_ITEMS'
export const ORDER_UPDATE_PAYMENT = 'ORDER_UPDATE_PAYMENT'
export const ORDER_UPDATE_DELIVERY = 'ORDER_UPDATE_DELIVERY'
export const ORDER_LINK_DRAFT = 'ORDER_LINK_DRAFT'
export const ORDER_FETCH_DELIVERY_ADDRESSES = 'ORDER_FETCH_DELIVERY_ADDRESSES'
export const ORDER_FETCH_ORDERS = 'ORDER_FETCH_ORDERS'
