import { AUTH_IS_LOGGED_IN, AUTH_ROLES, USER_LANGUAGE } from '@/store/types.getters'
import router from '@/router'
import { USER_CHANGE_LANGUAGE } from '@/store/types.actions'

export const adminGuard = (to, from, next) => {
  // work-around to get to the Vuex store (as of Vue 2.0)
  const store = router.app.$options.store

  if (store.getters[AUTH_IS_LOGGED_IN] &&
    ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_OPERATOR', 'ROLE_CONTENT_MANAGER'].indexOf(store.getters[AUTH_ROLES][0]) !== -1) {
    next()
  } else {
    next({ name: 'home' })
  }
}

export const localeGuard = (to, from, next) => {
  let lang = to.name ? to.name.split('.')[0] : null
  lang = (lang === 'ru' || lang === 'en') ? lang : 'ro'

  // work-around to get to the Vuex store (as of Vue 2.0)
  const store = router.app.$options.store

  if (lang !== store.getters[USER_LANGUAGE]) {
    store.dispatch(USER_CHANGE_LANGUAGE, lang)
  }

  next()
}

export const authGuard = (to, from, next) => {
  // work-around to get to the Vuex store (as of Vue 2.0)
  const store = router.app.$options.store

  if (
    to.meta.hasOwnProperty('requiresAuth') &&
    to.meta.requiresAuth &&
    !store.getters[AUTH_IS_LOGGED_IN]
  ) {
    next({ name: 'home' })

    return
  }

  next()
}
