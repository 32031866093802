import Vue from 'vue'
import dayjs from 'dayjs'
import app from '@/main'
import { USER_LANGUAGE } from '@/store/types.getters'

Vue.filter('st', (value) => {
  return value ? value[app.$store.getters[USER_LANGUAGE]] : value
})

Vue.filter('formatPrice', (value) => {
  return value.toFixed(2)
})

Vue.filter('formatDate', (value) => {
  return dayjs(value, 'YYYY-MM-DD[T]HH:mm:ss[P]').format('DD.MM.YYYY')
})

Vue.filter('formatDateTime', (value) => {
  return dayjs(value, 'YYYY-MM-DD[T]HH:mm:ss[P]').format('DD.MM.YYYY HH:mm')
})

Vue.filter('dateFormat', (date, format) => {
  return dayjs(date).format(format)
})

Vue.filter('stripTags', (str) => {
  return str
    .replace(/<style[^>]+>[^>]+<\/style>/g, '')
    .replace(/<\/?[^>]+(>|$)/g, '')
})
