import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n, loadLanguageAsync } from './setup/i18n'
import './setup/mq'
import './setup/vee-validate'
import './setup/bootstrap-vue'
import './setup/vue-gtm'
import './setup/sentry'
import './services/filters'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { USER_LANGUAGE } from '@/store/types.getters'
import TRouterLink from '@/components/general/link/TRouterLink'
import ProductLink from '@/components/general/link/ProductLink'
import RouterMixin from '@/services/mixins/router-mixin'
import Meta from 'vue-meta'
import Fragment from 'vue-fragment'
import { VueMasonryPlugin } from 'vue-masonry'
import { USER_CHANGE_LANGUAGE } from '@/store/types.actions'
import 'leaflet/dist/leaflet.css'

Vue.use(VueMasonryPlugin)
Vue.use(Fragment.Plugin)
Vue.use(VueAwesomeSwiper)
Vue.use(Meta)

Vue.component('TRouterLink', TRouterLink)
Vue.component('ProductLink', ProductLink)

Vue.mixin(RouterMixin)

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  created () {
    if (this.$store.getters[USER_LANGUAGE] === 'en') {
      this.$store.dispatch(USER_CHANGE_LANGUAGE, 'ro')
    }

    loadLanguageAsync(this.$store.getters[USER_LANGUAGE])
  },
  mounted () {
    // Fix related to click detection in iOS
    // More info: https://github.com/kentor/react-click-outside#not-working-on-ios
    if ('ontouchstart' in document.documentElement) {
      document.body.style.cursor = 'pointer'
    }
  },
  render: h => h(App)
}).$mount('#app')
