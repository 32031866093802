const requireModule = require.context('.', true, /\.js$/)
const modules = {}

function fileNameToModule (fileName) {
  return fileName.replace(/(\.\/|\.js)/g, '')
    .replace(/-(.)/g, ($1) => $1.toUpperCase())
    .replace(/[-_]+/g, '')
}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const moduleName = fileNameToModule(fileName)

  modules[moduleName] = requireModule(fileName).default
})

export default modules
