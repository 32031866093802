export default [
  {
    path: 'login',
    name: 'auth.login',
    meta: { bodyClass: 'security-page' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login')
  },
  {
    path: 'login/vk',
    name: 'auth.vk_callback',
    meta: { bodyClass: 'security-page' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/VKCallback')
  },
  {
    path: 'registration',
    name: 'auth.registration',
    meta: { bodyClass: 'security-page' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Registration')
  },
  {
    path: 'registration/confirm-email/:token',
    name: 'auth.confirm_email',
    meta: { bodyClass: 'security-page' },
    props: true,
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ConfirmEmail')
  },
  {
    path: 'reset-password',
    name: 'auth.request_reset_password',
    meta: { bodyClass: 'security-page' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/RequestResetPassword')
  },
  {
    path: 'reset-password/:token',
    name: 'auth.reset_password',
    meta: { bodyClass: 'security-page' },
    props: true,
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ResetPassword')
  }
]
