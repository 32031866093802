export const AUTH_IS_LOGGED_IN = 'AUTH_IS_LOGGED_IN'
export const AUTH_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN'
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN'
export const AUTH_ROLES = 'AUTH_ROLES'
export const AUTH_HAS_ADMIN_GROUP_ROLE = 'AUTH_HAS_ADMIN_GROUP_ROLE'
export const AUTH_HAS_OPERATOR_GROUP_ROLE = 'AUTH_HAS_OPERATOR_GROUP_ROLE'
export const AUTH_HAS_CONTENT_MANAGER_GROUP_ROLE = 'AUTH_HAS_CONTENT_MANAGER_GROUP_ROLE'

export const GENERAL_IS_ACTIVE_LOADER = 'GENERAL_IS_ACTIVE_LOADER'
export const GENERAL_BANNER_BY_POSITION = 'GENERAL_BANNER_BY_POSITION'
export const GENERAL_META_BY_KEY = 'GENERAL_META_BY_KEY'
export const GENERAL_CITIES = 'GENERAL_CITIES'
export const GENERAL_CURRENT_CITY = 'GENERAL_CURRENT_CITY'
export const GENERAL_CURRENT_CITY_ID = 'GENERAL_CURRENT_CITY_ID'
export const GENERAL_CITY_BY_ID = 'GENERAL_CITY_BY_ID'
export const GENERAL_FORCE_HIDE_FREE_BOTTLE_BANNER = 'GENERAL_FORCE_HIDE_FREE_BOTTLE_BANNER'

export const USER_PROFILE = 'USER_PROFILE'
export const USER_LANGUAGE = 'USER_LANGUAGE'

export const SHOP_CATEGORIES = 'SHOP_CATEGORIES'
export const SHOP_CATEGORY = 'SHOP_CATEGORY'
export const SHOP_PRODUCT = 'SHOP_PRODUCT'
export const SHOP_LAST_SEEN_PRODUCTS = 'SHOP_LAST_SEEN_PRODUCTS'
export const SHOP_SEARCH_QUERY = 'SHOP_SEARCH_QUERY'
export const SHOP_CATEGORY_FILTERS = 'SHOP_CATEGORY_FILTERS'

export const ORDER_DRAFT = 'ORDER_DRAFT'
export const ORDER_DRAFT_TOKEN = 'ORDER_DRAFT_TOKEN'
export const ORDER_DELIVERY_ADDRESSES = 'ORDER_DELIVERY_ADDRESSES'
export const ORDER_ORDERS = 'ORDER_ORDERS'

export const SHOW_POPUP = 'SHOW_POPUP'
