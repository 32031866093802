<template>
  <div class="product-item" >
    <div :class="{'not-in-stock': !product.inStock }">
      <div v-if="newMark" class="label-new">{{ $t('product.new') }}</div>

      <div v-if="product.discount" class="label-sale">-{{ product.discount }}%</div>

      <a v-if="allowBuy" href="#" class="mobile-cart" @click.prevent="$root.$emit('add-to-card-mobile:show', product)" />

      <div class="img-product">
        <ProductLink :product="product">
          <img v-if="product.coverImage" :src="product.coverImage.absolutePath">
        </ProductLink>
      </div>

      <div class="product-name">
        <ProductLink :product="product">
          {{ product.name|st }}
        </ProductLink>
      </div>

      <div class="product-price">{{ product.price }} {{ $t('currency.lei') }}</div>

      <div v-if="product.discount" class="old-price">{{ product.originalPrice }} {{ $t('currency.lei') }}</div>

      <a v-if="allowRemoveFromFavorite" class="remove-product" href="#" @click.prevent="unfavoriteProduct()" />

      <div v-if="product.inStock && allowBuy && $mq === 'xl'" class="add-to-cart">
        <div class="product-name">
          <ProductLink :product="product" />
        </div>

        <div class="product-price">{{ totalPrice|formatPrice }} {{ $t('currency.lei') }}</div>

        <div class="count-product">
          <span class="remove" @click="quantity = Math.max(parseInt(quantity) - 1, 1)" />

          <input v-model="quantity" type="text">

          <div class="add-product" @click="quantity = parseInt(quantity) + 1" />
        </div>

        <a class="add-products" href="#" @click.prevent="addProductToOrder({ product: product, quantity: quantity })" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ORDER_ADD_PRODUCT } from '@/store/types.actions'
import { unfavoriteProduct } from '@/api/shop'

export default {
  name: 'ProductPreview',
  props: {
    product: {
      type: Object,
      required: true
    },
    allowRemoveFromFavorite: {
      type: Boolean,
      default: false
    },
    newMark: {
      type: Boolean,
      default: false
    },
    allowBuy: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      quantity: 1,
    }
  },
  computed: {
    totalPrice () {
      return this.quantity * this.product.price
    }
  },
  methods: {
    ...mapActions({
      addProductToOrder: ORDER_ADD_PRODUCT
    }),
    async unfavoriteProduct () {
      await unfavoriteProduct(null, this.product.id)
      this.$emit('unfavorited', this.product)
    }
  }
}
</script>

<style scoped>
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .product-item .not-in-stock {
    opacity: 0.4;
    //pointer-events: none;
  }
</style>
