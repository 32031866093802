<template>
  <footer class="footer">
    <div class="inner-footer">
      <div class="width-locker">
        <div class="row">
          <div class="col-md-3 col-xs-6">
            <img class="logo" alt="logo" src="../../../assets/img/logo_footer.webp">
            <img class="mobile-logo" alt="logo" src="../../../assets/img/mobile_logo.webp">
          </div>

          <div class="box-links col-md-3">
            <div class="title">{{ $t('footer.info') }}</div>

            <ul>
              <li><t-router-link :to="{ name: 'platform_content.payment_and_delivery' }">{{ $t('payment_and_delivery') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.contact_us' }">{{ $t('contact_us') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.faq' }">{{ $t('faq') }}</t-router-link></li>
            </ul>
          </div>

          <div class="box-links col-md-3">
            <div class="title">{{ $t('about_us') }}</div>

            <ul>
              <li><t-router-link :to="{ name: 'platform_content.about_company' }">{{ $t('about_company') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.feedbacks' }">{{ $t('feedbacks') }}</t-router-link></li>
            </ul>
          </div>

          <div class="col-md-3 col-xs-6">
            <div class="footer-contact">
              <div class="phone">14200</div>
              <div class="work-on">08:00 - 17:00</div>
              <div class="footer-contact__cards-logos">
                <img alt="Visa logo" src="../../../assets/img/order/visa_icn.webp">
                <img alt="MasterCard logo" src="../../../assets/img/order/master_icn.webp">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright">Copyright © 2008-{{ new Date().getFullYear() }} OM14200.MD</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
