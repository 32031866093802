<template>
  <div class="latest-news">
    <div class="width-locker">
      <div class="title-news">{{ $t('static_page.news.title') }}</div>
      <div class="row">
        <div v-for="news in newsList" :key="news.id" class="col-md-4">
          <div class="box-news">
            <img class="news-img" alt="news" :src="news.coverImage.absolutePath">
            <div class="news-text">{{ news.title|st }}</div>

            <t-router-link :to="{ name: 'platform_content.news', params: { slug: $options.filters.st(news.slug) } }" class="link-more">
              {{ $t('static_page.news.details') }}
            </t-router-link>
          </div>
        </div>
      </div>

      <div class="row btn-wrap">
        <div class="col-md-12">
          <t-router-link :to="{ name: 'platform_content.list_news' }" class="btn-more-news">
            {{ $t('static_page.news.see_more') }}
          </t-router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/general'

export default {
  name: 'LatestNewsBlock',
  data () {
    return {
      newsList: []
    }
  },
  async created () {
    const response = await api.newsList({ per_page: 3 })

    this.newsList = response.data._embedded.items
  }
}
</script>
