<template>
  <div v-if="isShown" class="overlay-alert open" style="z-index: 9999">
    <a class="close-btn" href="#" style="z-index: 99999" @click.prevent="close">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 28 28" style="enable-background:new 0 0 28 28;" xml:space="preserve">
        <g>
          <path d="M0,24l4,4l10-10l10,10l4-4L18,14L28,4l-4-4L14,10L4,0L0,4l10,10L0,24z"/>
        </g>
      </svg>
    </a>

    <div class="inner-alert">
      <a :href="popup.link" target="_blank">
        <img :src="popup.image.absolutePath" :alt="popup.name">
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

export default {
  name: 'Popup',
  data () {
    return {
      isShown: false,
      popup: null
    }
  },
  watch: {
    '$store.getters.SHOW_POPUP': {
      immediate: true,
      handler(value)  {
        if (value) {
          this.show(value)
        }
      }
    }
  },
  methods: {
    show (popup) {
      const url = window.location.protocol + '//' + window.location.host + window.location.pathname;
      const cookiePopupKey = `popup.${popup.id}.${url}`;

      if (popup && !this.$cookies.isKey(cookiePopupKey)) {
        this.popup = popup
        this.isShown = true
        this.$cookies.set(cookiePopupKey, true)

        setTimeout(() => this.close(), this.popup.timer * 1000)
      }

    },
    close () {
      this.isShown = false
      this.popup = null

      this.$emit('closed')
    }
  }
}
</script>
<style>
  .inner-alert {
    padding: 0 !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    max-width: 800px !important;
    max-height: 90vh !important;
    width: inherit !important;
    background: none !important;
  }

  .inner-alert img {
    max-width: 100%;
    max-height: 100%;
  }

  .overlay-alert .close-btn {
    text-decoration: none;
    display: block;
    height: 35px;
    width: 35px;
    position: fixed;
    right: 40px;
    top: 20px;
    opacity: 1;
    font-size: 35px;
    font-weight: 900;
  }

  .overlay-alert .close-btn svg {
    fill: #fff;
  }

  .overlay-alert .close-btn svg:hover {
    fill: #f2dede;
  }

  @media screen and (min-width: 0px) and (max-width: 840px) {
    .inner-alert {
      padding-right: 40px !important;
      padding-left: 40px !important;
    }

    .overlay-alert .close-btn {
      right: 20px;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 600px) {
    .inner-alert {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
</style>
