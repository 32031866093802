export default [
  {
    path: 'banners',
    name: 'auth.banner.list',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/BannerList')
  },
  {
    path: 'banner/:id(\\d+)',
    name: 'auth.banner.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/UpdateBanner')
  },
  {
    path: 'banner/create',
    name: 'auth.banner.create',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/CreateBanner')
  }
]
