import {
  getLoaderRequest,
  patchLoaderRequest,
  putLoaderRequest,
  postLoaderRequest,
  patchRequest,
  getRequest, deleteLoaderRequest
} from '@/services/requests'

export const draftOrder = getRequest('/v1/order/draft')
export const addProductToOrder = postLoaderRequest('/v1/order/products/:product_id/add')
export const deleteProductFromOrder = postLoaderRequest('/v1/order/products/:product_id/delete')
export const updateProductFromOrder = patchRequest('/v1/order/products/:product_id/update')
export const validateOrderItems = postLoaderRequest('/v1/order/validate-items')
export const updatePaymentInfo = patchLoaderRequest('/v1/order/payment')
export const bankCallback = postLoaderRequest('/v1/payment/bank-callback')
export const getBankRequisites = getLoaderRequest('/v1/payment/bank-requisites')
export const updateDeliveryInfo = patchLoaderRequest('/v1/order/delivery')
export const linkDraftOrder = postLoaderRequest('/v1/order/link')
export const deliveryAddresses = getLoaderRequest('/v1/delivery-addresses')
export const deliveryAddress = getLoaderRequest('/v1/delivery-address/:delivery_address_id')
export const createDeliveryAddress = postLoaderRequest('/v1/delivery-address/create')
export const updateDeliveryAddress = putLoaderRequest('/v1/delivery-address/:delivery_address_id/update')
export const deleteDeliveryAddress = deleteLoaderRequest('/v1/delivery-address/:delivery_address_id')
export const orders = getLoaderRequest('/v1/orders')
export const order = getLoaderRequest('/v1/order/:id')
export const repeatOrder = postLoaderRequest('/v1/order/:id/repeat')
export const blockedDeliveryDates = getLoaderRequest('/v1/city/:id/blocked-delivery-dates')
export const orderTracking = getLoaderRequest('/v1/order/:delivery_route_external_id/tracking')
