export default [
  {
    path: 'about-company',
    name: 'platform_content.about_company',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/AboutCompany')
  },
  {
    path: 'certification',
    name: 'platform_content.certification',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/Certification')
  },
  {
    path: 'payment-and-delivery',
    name: 'platform_content.payment_and_delivery',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/PaymentAndDelivery')
  },
  {
    path: 'feedbacks',
    name: 'platform_content.feedbacks',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/Feedbacks')
  },
  {
    path: 'contact-us',
    name: 'platform_content.contact_us',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/ContactUs')
  },
  {
    path: 'faq',
    name: 'platform_content.faq',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/FAQ')
  },
  {
    path: 'news',
    name: 'platform_content.list_news',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/ListNews')
  },
  {
    path: 'news/:slug',
    name: 'platform_content.news',
    props: true,
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/News')
  },
  {
    path: 'terms-and-conditions',
    name: 'platform_content.terms_and_conditions',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/TermsAndConditions')
  },
  {
    path: 'commercial-offer',
    name: 'platform_content.commercial_offer',
    component: () => import(/* webpackChunkName: "platform-content" */ '../views/static-pages/CommercialOffer')
  }
]
