export default [
  {
    path: 'blocked-delivery-dates',
    name: 'admin.blocked_delivery_date.list',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/order/BlockedDeliveryDateList')
  },
  {
    path: 'blocked-delivery-date/:id(\\d+)',
    name: 'admin.blocked_delivery_date.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/order/UpdateBlockedDeliveryDate')
  },
  {
    path: 'blocked-delivery-date/create',
    name: 'admin.blocked_delivery_date.create',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/order/CreateBlockedDeliveryDate')
  }
]
