<template>
  <div v-if="text" class="seo-block">
    <div class="width-locker">
      <hr>

      <div class="seo-block--text" :class="{ 'seo-block--text__collapsed': collapsed }" v-html="text" />

      <a v-if="collapsed" href="#" class="seo-block--link-more" @click.prevent="collapsed = false">
        {{ $t('button.more_details') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeoBlock',
  props: {
    text: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      collapsed: true
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1170px) and (min-width: 0px) {
  .seo-block {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  div.seo-block {
    margin-bottom: 40px;
  }
}

.seo-block {
  margin-bottom: 20px;

  .seo-block--text {
    margin-top: 40px;
    font-size: 15px;
    color: #9c8984;
    line-height: 20px;
    position: relative;

    &__collapsed {
      max-height: 150px;
      overflow: hidden;

      &::after {
        content  : "";
        position : absolute;
        z-index  : 1;
        bottom   : 0;
        left     : 0;
        pointer-events   : none;
        background-image : linear-gradient(to bottom,
          rgba(255,255,255, 0),
          rgba(255,255,255, 1) 90%);
        width    : 100%;
        height   : 4em;
      }
    }
  }

  .seo-block--link-more {
    margin-top: 15px;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    color: #7ea77b;
    text-transform: uppercase;
    font-family: "Akrobat";
    font-weight: 700;
    position: relative;
    padding-right: 20px;
    border-bottom: 1px solid transparent;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 13px;
      height: 12px;
      right: 4px;
      top: 6px;
      background: url(../../../assets/img/sprite_icon.webp) -117px -91px no-repeat;
    }
  }
}
</style>
