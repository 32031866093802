import axios from '@/setup/axios'

export const postRequest = createRequest('post')
export const postLoaderRequest = createLoaderRequest('post')
export const patchLoaderRequest = createLoaderRequest('patch')
export const patchRequest = createRequest('patch')
export const putLoaderRequest = createLoaderRequest('put')
export const deleteLoaderRequest = createLoaderRequest('delete')

export const getLoaderRequest = (url) => {
  return (data, urlParams) => {
    return axios.get(prepareUrl(url, urlParams), {
      params: data,
      loader: true
    })
  }
}

export const getRequest = (url) => {
  return (data, urlParams, requestId, config) => {
    return axios.get(prepareUrl(url, urlParams), {
      params: data,
      requestId: requestId,
      ...config
    })
  }
}

function createLoaderRequest (method) {
  return (url) => {
    return (data, urlParams, params) => {
      return axios.request({
        method: method,
        url: prepareUrl(url, urlParams),
        data: data,
        params: params,
        loader: true
      })
    }
  }
}

function createRequest (method) {
  return (url) => {
    return (data, urlParams) => {
      return axios[method](prepareUrl(url, urlParams), data)
    }
  }
}

function prepareUrl (url, params) {
  const preparedParams = typeof params === 'object' ? params : [params]
  let position = 0

  return url.replace(/:\w+/g, () => {
    return preparedParams[position++]
  })
}
