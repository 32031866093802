<template>
  <li :style="{ background: 'url(' + orderProduct.product.coverImage.absolutePath + ') 20px 20px no-repeat', backgroundSize: '80px auto' }">
    <a v-if="!orderProduct.service" class="remove-product" href="#" @click.prevent="removeProduct(orderProduct.product)" />

    <div class="desc-product">{{ orderProduct.product.name|st }}</div>

    <div class="count-product">
      <span class="remove" @click="updateQuantity(orderProduct.quantity - 1)" />
      <input
        type="text"
        :value="orderProduct.quantity"
        :disabled="orderProduct.service"
        :class="{ 'water-bottle-input': orderProduct.service }"
        @change="({ target }) => updateQuantity(target.value)"
      >
      <div class="add-product" @click="updateQuantity(orderProduct.quantity + 1)" />
    </div>

    <div class="price-product">{{ (orderProduct.totalPrice)|formatPrice }} {{ $t('currency.lei') }}</div>
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import { ORDER_REMOVE_PRODUCT, ORDER_UPDATE_PRODUCT } from '@/store/types.actions'

export default {
  name: 'HeaderOrderItem',
  props: {
    orderProduct: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      removeProduct: ORDER_REMOVE_PRODUCT
    }),
    updateQuantity (quantity) {
      if (quantity && !this.orderProduct.service) {
        this.$store.dispatch(ORDER_UPDATE_PRODUCT, {
          product: this.orderProduct.product,
          quantity: quantity
        })
      }
    }
  }
}
</script>

<style scoped>
  .water-bottle-input {
    background-color: transparent;
  }
</style>
