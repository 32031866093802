export default [
  {
    path: 'categories',
    name: 'auth.category.list',
    component: () => import(/* webpackChunkName: "admin-category" */ '../../views/admin/shop/CategoryList')
  },
  {
    path: 'category/:id(\\d+)',
    name: 'auth.category.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-category" */ '../../views/admin/shop/UpdateCategory')
  },
  {
    path: 'category/create',
    name: 'auth.category.create',
    component: () => import(/* webpackChunkName: "admin-category" */ '../../views/admin/shop/CreateCategory')
  }
]
