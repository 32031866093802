export default [
  {
    path: 'users',
    name: 'auth.user.list',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/user/UserList')
  },
  {
    path: 'profile/:id(\\d+)',
    name: 'auth.user.show',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/user/UserPreview')
  },
  {
    path: 'user/:id(\\d+)',
    name: 'auth.user.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/user/UpdateUser')
  },
  {
    path: 'user/create',
    name: 'auth.user.create',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/user/CreateUser')
  }
]
