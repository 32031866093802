import { getLoaderRequest, getRequest, postLoaderRequest } from '@/services/requests'

export const categories = getLoaderRequest('/v1/categories')
export const category = getLoaderRequest('/v1/category/:slug')
export const categoryFilters = getLoaderRequest('/v1/categories/filters')
export const productOfDay = getLoaderRequest('/v1/products/day')
export const products = getLoaderRequest('/v1/products')
export const product = getLoaderRequest('/v1/products/:slug')
export const lastSeenProducts = getLoaderRequest('/v1/products/last-seen')
export const relatedProducts = getLoaderRequest('/v1/product/:id/related')
export const favoriteProduct = postLoaderRequest('/v1/products/:id/favorite')
export const unfavoriteProduct = postLoaderRequest('/v1/products/:id/unfavorite')
export const search = getRequest('/v1/products/search')
export const searchWithLoader = getLoaderRequest('/v1/products/search')
