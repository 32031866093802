import * as api from '@/api/shop'
import * as actionTypes from '../types.actions'
import * as mutationTypes from '../types.mutations'
import * as getterTypes from '../types.getters'

const state = {
  categories: [],
  category: null,
  categoryFilters: null,
  product: null,
  lastSeenProducts: [],
  searchQuery: null
}

const getters = {
  [getterTypes.SHOP_CATEGORIES]: state => state.categories,
  [getterTypes.SHOP_CATEGORY]: state => state.category,
  [getterTypes.SHOP_PRODUCT]: state => state.product,
  [getterTypes.SHOP_LAST_SEEN_PRODUCTS]: state => state.lastSeenProducts,
  [getterTypes.SHOP_SEARCH_QUERY]: state => state.searchQuery,
  [getterTypes.SHOP_CATEGORY_FILTERS]: state => state.categoryFilters
}

const actions = {
  [actionTypes.SHOP_FETCH_CATEGORIES] ({ commit }) {
    return api.categories()
      .then(({ data }) => commit(mutationTypes.SHOP_SET_CATEGORIES, data))
  },
  [actionTypes.SHOP_FETCH_CATEGORY] ({ commit }, slug) {
    return api.category(null, slug)
      .then(({ data }) => commit(mutationTypes.SHOP_SET_CATEGORY, data))
  },
  [actionTypes.SHOP_FETCH_PRODUCT_OF_DAY] ({ getters }) {
    return api.productOfDay({ city_id: getters[getterTypes.GENERAL_CURRENT_CITY_ID] })
  },
  [actionTypes.SHOP_FETCH_PRODUCTS] ({ getters }, payload) {
    return api.products({ ...payload, city_id: getters[getterTypes.GENERAL_CURRENT_CITY_ID] })
  },
  [actionTypes.SHOP_FETCH_PRODUCT] ({ commit, getters }, slug) {
    return api.product({ city_id: getters[getterTypes.GENERAL_CURRENT_CITY_ID] }, slug)
      .then(({ data }) => {
        commit(mutationTypes.SHOP_SET_PRODUCT, data)
        commit(mutationTypes.SHOP_SET_CATEGORY, data.category)
      })
  },
  [actionTypes.SHOP_FETCH_LAST_SEEN_PRODUCTS] ({ commit, getters }) {
    if (!getters[getterTypes.AUTH_IS_LOGGED_IN]) {
      return Promise.resolve()
    }

    return api.lastSeenProducts()
      .then(({ data }) => commit(mutationTypes.SHOP_SET_LAST_SEEN_PRODUCTS, data._embedded.items))
  },
  [actionTypes.SHOP_FETCH_RELATED_PRODUCTS] ({ getters }, productId) {
    return api.relatedProducts({ city_id: getters[getterTypes.GENERAL_CURRENT_CITY_ID] }, productId)
  },
  [actionTypes.SHOP_TOGGLE_PRODUCT_FAVORITE] ({ state, commit }) {
    if (state.product.favorite) {
      return api.unfavoriteProduct(null, state.product.id)
        .then(() => {
          const product = Object.assign({}, state.product)

          product.favorite = false

          commit(mutationTypes.SHOP_SET_PRODUCT, product)
        })
    } else {
      return api.favoriteProduct(null, state.product.id)
        .then(() => {
          const product = Object.assign({}, state.product)

          product.favorite = true

          commit(mutationTypes.SHOP_SET_PRODUCT, product)
        })
    }
  },
  [actionTypes.SHOP_FETCH_CATEGORY_FILTERS] ({ getters, commit }) {
    const category = getters[getterTypes.SHOP_CATEGORY]

    return api.categoryFilters({ category_id: category ? category.id : null })
      .then(({ data }) => commit(mutationTypes.SHOP_SET_CATEGORY_FILTERS, data))
  }
}

const mutations = {
  [mutationTypes.SHOP_SET_CATEGORIES] (state, data) {
    state.categories = data._embedded.items
  },
  [mutationTypes.SHOP_SET_CATEGORY] (state, data) {
    state.category = data
  },
  [mutationTypes.SHOP_SET_PRODUCT] (state, data) {
    state.product = data
  },
  [mutationTypes.SHOP_SET_LAST_SEEN_PRODUCTS] (state, data) {
    state.lastSeenProducts = data
  },
  [mutationTypes.SHOP_SET_CATEGORY_FILTERS] (state, data) {
    state.categoryFilters = data
  },
  [mutationTypes.SHOP_SET_SEARCH_QUERY] (state, searchQuery) {
    state.searchQuery = searchQuery
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
