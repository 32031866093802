export default [
  {
    path: 'news',
    name: 'auth.news.list',
    component: () => import(/* webpackChunkName: "admin-news" */ '../../views/admin/platformContent/NewsList')
  },
  {
    path: 'news/:id(\\d+)',
    name: 'auth.news.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-news" */ '../../views/admin/platformContent/UpdateNews')
  },
  {
    path: 'news/create',
    name: 'auth.news.create',
    component: () => import(/* webpackChunkName: "admin-news" */ '../../views/admin/platformContent/CreateNews')
  }
]
