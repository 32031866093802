export default [
  {
    path: 'orders',
    name: 'auth.order.list',
    component: () => import(/* webpackChunkName: "admin-order" */ '../../views/admin/order/OrderList')
  },
  {
    path: 'order/:id(\\d+)',
    name: 'auth.order.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-order" */ '../../views/admin/order/UpdateOrder')
  }
]
