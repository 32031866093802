<template>
  <div>
    <div class="width-locker">
<!--      <FreeBottleBanner />-->

      <div>
        <div class="left-menu mouseenter">
          <div class="caption">{{ $t('product_categories') }}</div>

          <ul>
            <li v-for="category in categories.slice(0, 10)" :key="category.id">
              <t-router-link
                :style="{ backgroundImage: category.icon ? 'url(' + category.icon.absolutePath + ')' : null, backgroundPositionX: '20px', backgroundPositionY: '50%', backgroundRepeat: 'no-repeat' }"
                :to="{ name: 'shop.category', params: { categorySlug: $options.filters.st(category.slug) } }"
              >
                {{ category.name|st }}
              </t-router-link>
            </li>
          </ul>

          <t-router-link :to="{ name: 'shop.main' }" class="load-more">
            <span />
            <span />
            <span />
          </t-router-link>
        </div>

        <Carousel />
      </div>

      <div class="commercial-offer-banner">
        <div class="commercial-offer-banner__info">
          <div>{{ $t('home.commercial_offer.title') }}</div>

          <t-router-link :to="{ name: 'platform_content.commercial_offer' }">
            {{ $t('home.commercial_offer.button') }}
          </t-router-link>
        </div>
      </div>

      <WaterBottles />

      <PopularProducts />

      <NewProducts />

      <div class="banners">
        <div class="row">
          <div v-if="bannerByPosition('homepage-left')" class="col-md-6">
            <a :href="bannerByPosition('homepage-left').link" target="_blank">
              <img :src="bannerByPosition('homepage-left').image.absolutePath">
            </a>
          </div>

          <div v-if="bannerByPosition('homepage-right')" class="col-md-6">
            <a :href="bannerByPosition('homepage-right').link" target="_blank">
              <img :src="bannerByPosition('homepage-right').image.absolutePath">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="delivery">
      <div class="width-locker">
        <div class="row">
          <div class="col-md-12">
            <div class="title">{{ $t('home.delivery.title') }}</div>
          </div>

          <div class="col-md-4 delivery-point">
            <ul>
              <li class="simple">
                <div class="title">{{ $t('home.delivery.simple.title') }}</div>
                <div class="text">
                  {{ $t('home.delivery.simple.description') }}
                </div>
              </li>
              <li class="saves-time">
                <div class="title">{{ $t('home.delivery.saves_time.title') }}</div>
                <div class="text">
                  {{ $t('home.delivery.saves_time.description') }}
                </div>
              </li>
            </ul>
          </div>

          <div class="col-md-4 delivery-point">
            <ul>
              <li class="comfortable">
                <div class="title">{{ $t('home.delivery.comfortable.title') }}</div>
                <div class="text">
                  {{ $t('home.delivery.comfortable.description') }}
                </div>
              </li>
              <li class="saves-money">
                <div class="title">{{ $t('home.delivery.saves_money.title') }}</div>
                <div class="text">
                  {{ $t('home.delivery.saves_money.description') }}
                </div>
              </li>
            </ul>
          </div>

          <div class="col-md-4">
            <div class="delivery-schedule">
              <div class="title">{{ $t('home.delivery_schedule.title') }}</div>
              <div class="work-on">8:00 - 20:00</div>
              <div class="text">{{ $t('home.delivery_schedule.description') }}</div>
              <t-router-link :to="{ name: 'platform_content.payment_and_delivery' }" class="btn-delivery">{{ $t('home.delivery_schedule.details') }}</t-router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FeedbacksSlider />

    <LatestNewsBlock />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GENERAL_FETCH_BANNERS, ORDER_ADD_PRODUCT } from '@/store/types.actions'
import { SHOP_CATEGORIES, GENERAL_BANNER_BY_POSITION } from '@/store/types.getters'
import WaterBottles from '@/components/shop/products-block/WaterBottles'
import PopularProducts from '@/components/shop/products-block/PopularProducts'
import NewProducts from '@/components/shop/products-block/NewProducts'
import LatestNewsBlock from '@/components/static-pages/news/LatestNewsBlock'
import FeedbacksSlider from '@/components/static-pages/feedback/FeedbacksSlider'
import Carousel from '@/components/general/Carousel'

export default {
  name: 'Home',
  components: { Carousel, FeedbacksSlider, LatestNewsBlock, NewProducts, PopularProducts, WaterBottles },
  computed: {
    ...mapGetters({
      categories: SHOP_CATEGORIES,
      bannerByPosition: GENERAL_BANNER_BY_POSITION
    })
  },
  watch: {
    '$store.getters.USER_LANGUAGE': 'simpleChangeLocaleRedirect'
  },
  created () {
    this.$store.dispatch(GENERAL_FETCH_BANNERS)
  },
  methods: {
    ...mapActions({
      addProductToOrder: ORDER_ADD_PRODUCT
    })
  }
}
</script>
