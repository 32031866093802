import VueI18n from 'vue-i18n'
import Vue from 'vue'
import messagesRu from '@/lang/ru'
import messagesRo from '@/lang/ro'
import { Validator } from 'vee-validate'

window.Vue = Vue

Vue.use(VueI18n)

// let loadedLanguages = ['ru', 'ro']
export const SUPPORTED_LANGUAGES = ['ru', 'ro']
export const DEFAULT_LANGUAGE = 'ro'

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  messages: {
    ru: messagesRu,
    ro: messagesRo
  }
})

function setI18nLanguage (lang) {
  i18n.locale = lang
  Validator.localize(lang)

  document.querySelector('html').setAttribute('lang', lang)

  return lang
}

export function loadLanguageAsync (lang) {
  if (!SUPPORTED_LANGUAGES.includes(lang)) {
    lang = DEFAULT_LANGUAGE
  }

  return Promise.resolve(setI18nLanguage(lang))
}
