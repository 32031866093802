<template>
  <div>
    <header class="header">
      <div class="header-top">
        <div class="width-locker">
          <div class="row">
            <div class="col-md-6">
              <ul class="nav-links">
                <li class="lang"><a href="#" :class="{ active: language === 'ro' }" @click.prevent="changeLanguage('ro')">{{ $t('language.ro') }}</a></li>
                <li class="lang last-child"><a href="#" :class="{ active: language === 'ru' }" @click.prevent="changeLanguage('ru')">{{ $t('language.ru') }}</a></li>

                <li><t-router-link :to="{ name: 'platform_content.about_company' }">{{ $t('about_company') }}</t-router-link></li>
                <li><t-router-link :to="{ name: 'platform_content.feedbacks' }">{{ $t('feedbacks') }}</t-router-link></li>
                <li><t-router-link :to="{ name: 'platform_content.terms_and_conditions' }">{{ $t('terms_and_conditions') }}</t-router-link></li>
                <li><t-router-link :to="{ name: 'platform_content.contact_us' }">{{ $t('contact_us') }}</t-router-link></li>
                <li><t-router-link :to="{ name: 'platform_content.faq' }">{{ $t('faq') }}</t-router-link></li>
              </ul>
            </div>

            <div class="col-md-6">
              <template v-if="isLoggedIn && profile">
                <div class="user">
                  <span class="user-name" />
                  <div class="user-dropdown">
                    <ul class="user-info">
                      <li class="data-user"><t-router-link :to="{ name: 'user.edit_profile' }">{{ $t('header.user.profile') }}</t-router-link></li>
                      <li class="favorite"><t-router-link :to="{ name: 'user.favorite_products' }">{{ $t('header.user.favorite_products') }}</t-router-link></li>

                      <li v-if="hasAdminGroupRole">
                        <a href="/admin">{{ $t('header.user.admin_dashboard') }}</a>
                      </li>
                    </ul>

                    <div class="logout">
                      <a href="#" @click.prevent="logout()">{{ $t('auth.logout') }}</a>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <t-router-link :to="{ name: 'auth.login' }" class="login-btn">{{ $t('auth_or_registration') }}</t-router-link>
              </template>

              <a class="location" href="#" @click.prevent="$root.$emit('city-selector-modal:open')">
                <template v-if="currentCity">{{ currentCity.name|st }}</template>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="header-search">
        <div class="width-locker">
          <div class="row">
            <div class="col-md-12">
              <div class="main-logo">
                <t-router-link :to="{ name: 'home' }">
                  <img class="logo" alt="logo" src="../../../assets/img/logo.webp">
                </t-router-link>
              </div>

              <div class="contact-box">
                <div class="contact">
                  <div class="contact-phone">14200</div>
                  <div class="work-time">08:00 - 17:00</div>
                </div>
              </div>

              <div class="middle-search">
                <HeaderSearch />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-menu">
        <div class="width-locker">
          <div class="row">
            <div class="col-md-12">
              <ul class="left-menu">
                <li class="dropdown-shop">
                  <div class="shop-link" @click="redirect({ name: 'shop.main' })">
                    {{ $t('shop') }}
                  </div>

                  <div class="shop-menu" :class="{ 'no-sub-categories': !hoveredCategory || !hoveredCategory.subCategories.length }">
                    <ul class="category-list">
                      <li v-for="category in categories" :key="category.id" @mouseover="hoveredCategory = category">
                        <t-router-link
                          :style="{ backgroundImage: category.icon ? 'url(' + category.icon.absolutePath + ')' : null, backgroundPositionX: 0, backgroundPositionY: '50%', backgroundRepeat: 'no-repeat' }"
                          :to="{ name: 'shop.category', params: { categorySlug: $options.filters.st(category.slug) } }"
                          :class="{ active: hoveredCategory === category }"
                        >
                          {{ category.name|st }}
                        </t-router-link>
                      </li>
                    </ul>

                    <ul v-if="hoveredCategory" class="category-links">
                      <li v-for="subCategory in hoveredCategory.subCategories" :key="subCategory.id">
                        <t-router-link :to="{ name: 'shop.category', params: { categorySlug: $options.filters.st(subCategory.slug) } }">
                          <span
                            v-if="subCategory.icon"
                            :style="{ backgroundImage: 'url(' + subCategory.icon.absolutePath + ')', backgroundRepeat: 'no-repeat', backgroundSize: '100%' }"
                          />
                          {{ subCategory.name|st }}
                        </t-router-link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li><t-router-link :to="{ name: 'platform_content.commercial_offer' }">{{ $t('commercial_offer.header') }}</t-router-link></li>
                <li><t-router-link :to="{ name: 'platform_content.payment_and_delivery' }">{{ $t('payment_and_delivery') }}</t-router-link></li>
                <li><t-router-link :to="{ name: 'platform_content.list_news' }">{{ $t('news') }}</t-router-link></li>
                <li v-if="isLoggedIn">
                  <t-router-link :to="{ name: 'order.history' }">{{ $t('header.user.order_history') }}</t-router-link>
                </li>
              </ul>

              <ul class="right-menu">
                <li v-if="isLoggedIn" class="favorites">
                  <t-router-link :to="{ name: 'user.favorite_products' }">{{ $t('favorite') }}</t-router-link>
                </li>

                <li class="cart">
                  <HeaderOrderInfo />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>

    <header v-if="$mq === 'sm' || $mq === 'md'" class="mobile-header">
      <a class="mobile-menu-btn" href="#" @click.prevent="showMobileMenu = !showMobileMenu" />

      <t-router-link :to="{ name: 'home' }">
        <img class="mobile-logo" alt="logo" src="../../../assets/img/logo.webp">
      </t-router-link>

      <t-router-link class="mobile-cart" :to="{ name: 'order.cart' }">
        <span class="product-count">{{ draftOrder ? draftOrder.orderProducts.length : 0 }}</span>
      </t-router-link>

      <a class="mobile-search" href="#" @click.prevent="showMobileSearch = true" />

      <HeaderSearchMobile
        v-if="showMobileSearch"
        :show-mobile-search="showMobileSearch"
        @close="showMobileSearch = false"
      />

      <div v-if="showMobileMenu" class="overlay-menu" :class="{ open: showMobileMenu }">
        <div v-on-clickaway="closeMobileMenu" class="mobile-menu">
          <template v-if="mobileMenuStep === 1">
            <ul class="mobile-links">
              <li><a href="#" @click.prevent="mobileMenuStep = 2">{{ $t('shop') }}</a></li>
              <li v-if="currentCity" class="location">
                <a href="#" @click.prevent="$root.$emit('city-selector-modal:open')">{{ currentCity.name|st }}</a>
              </li>
              <li><t-router-link :to="{ name: 'platform_content.commercial_offer' }">{{ $t('commercial_offer.header') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.payment_and_delivery' }">{{ $t('payment_and_delivery') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.list_news' }">{{ $t('news') }}</t-router-link></li>
              <li v-if="isLoggedIn">
                <t-router-link :to="{ name: 'order.history' }">{{ $t('header.user.order_history') }}</t-router-link>
              </li>
              <li><t-router-link :to="{ name: 'platform_content.about_company' }">{{ $t('about_company') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.feedbacks' }">{{ $t('feedbacks') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.terms_and_conditions' }">{{ $t('terms_and_conditions') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.contact_us' }">{{ $t('contact_us') }}</t-router-link></li>
              <li><t-router-link :to="{ name: 'platform_content.faq' }">{{ $t('faq') }}</t-router-link></li>
            </ul>

            <ul class="lang">
              <li><a href="#" :class="{ active: language === 'ro' }" @click.prevent="changeLanguage('ro')">{{ $t('language.ro') }}</a></li>
              <li><a href="#" :class="{ active: language === 'ru' }" @click.prevent="changeLanguage('ru')">{{ $t('language.ru') }}</a></li>
            </ul>

            <div class="mobile-menu-bottom">
              <div v-if="isLoggedIn && profile" class="user-mobile">
                <div class="toggle-head" :class="{ active: showMobileUserInfo }" @click="showMobileUserInfo = !showMobileUserInfo">
                  {{ profile.firstName }} {{ profile.lastName }}
                </div>

                <div class="user-dropdown">
                  <ul class="user-info">
                    <li class="data-user">
                      <t-router-link :to="{ name: 'user.edit_profile' }">{{ $t('header.user.profile') }}</t-router-link>
                    </li>
                    <li class="favorite">
                      <t-router-link :to="{ name: 'user.favorite_products' }">{{ $t('header.user.favorite_products') }}</t-router-link>
                    </li>
                  </ul>

                  <div class="logout">
                    <a href="#" @click.prevent="logout()">{{ $t('auth.logout') }}</a>
                  </div>
                </div>
              </div>

              <div v-else class="mobile-authorize">
                <t-router-link :to="{ name: 'auth.login' }">{{ $t('auth.login') }}</t-router-link> /
                <t-router-link :to="{ name: 'auth.registration' }">{{ $t('auth.registration') }}</t-router-link>
              </div>
            </div>
          </template>

          <ul v-if="mobileMenuStep === 2" class="mobile-menu-category">
            <li><a href="#" @click.prevent="mobileMenuStep = 1">{{ $t('button.back_to_menu') }}</a></li>

            <li v-for="category in categories" :key="category.id">
              <a
                href="#"
                :style="{ backgroundImage: category.icon ? 'url(' + category.icon.absolutePath + ')' : null, backgroundPositionX: 0, backgroundPositionY: '50%', backgroundRepeat: 'no-repeat' }"
                @click.prevent="() => selectCategoryMobile(category)"
              >
                {{ category.name|st }}
              </a>
            </li>
          </ul>

          <ul v-if="mobileMenuStep === 3 && selectedCategoryMobile" class="mobile-menu-subcategory">
            <li><a href="#" @click.prevent="mobileMenuStep = 2">{{ $t('button.back') }}</a></li>

            <li>
              <t-router-link :to="{ name: 'shop.category', params: { categorySlug: $options.filters.st(selectedCategoryMobile.slug) } }">
                {{ $t('label.all_products_in') }} <b>{{ selectedCategoryMobile.name|st }}</b>
              </t-router-link>
            </li>

            <li v-for="subCategory in selectedCategoryMobile.subCategories" :key="subCategory.id">
              <t-router-link
                :to="{ name: 'shop.category', params: { categorySlug: $options.filters.st(subCategory.slug) } }"
                :style="{ backgroundImage: subCategory.icon ? 'url(' + subCategory.icon.absolutePath + ')' : null, backgroundPositionX: '8%', backgroundPositionY: '50%', backgroundRepeat: 'no-repeat' }"
              >
                {{ subCategory.name|st }}
              </t-router-link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import * as getterTypes from '@/store/types.getters'
import { USER_CHANGE_LANGUAGE, AUTH_LOGOUT } from '@/store/types.actions'
import HeaderSearch from '@/components/layout/home-layout/HeaderSearch'
import HeaderSearchMobile from '@/components/layout/home-layout/HeaderSearchMobile'
import HeaderOrderInfo from '@/components/layout/home-layout/HeaderOrderInfo'

export default {
  name: 'Header',
  components: { HeaderOrderInfo, HeaderSearchMobile, HeaderSearch },
  mixins: [clickaway],
  data () {
    return {
      hoveredCategory: null,
      showMobileSearch: false,
      showMobileMenu: false,
      showMobileUserInfo: false,
      mobileMenuStep: 1,
      selectedCategoryMobile: null
    }
  },
  computed: {
    ...mapGetters({
      language: getterTypes.USER_LANGUAGE,
      isLoggedIn: getterTypes.AUTH_IS_LOGGED_IN,
      profile: getterTypes.USER_PROFILE,
      categories: getterTypes.SHOP_CATEGORIES,
      hasAdminGroupRole: getterTypes.AUTH_HAS_ADMIN_GROUP_ROLE,
      currentCity: getterTypes.GENERAL_CURRENT_CITY,
      draftOrder: getterTypes.ORDER_DRAFT
    })
  },
  watch: {
    showMobileMenu: 'showMobileMenuChanged',
    $route: 'closeMobileMenu'
  },
  created () {
    this.$root.$on('header:show-product-menu', this.showCategoryMenu)
  },
  beforeDestroy () {
    if (this.showMobileMenu) {
      enableBodyScroll(this.$root.$el)
    }

    this.$root.$off('header:show-product-menu', this.showCategoryMenu)
  },
  methods: {
    logout () {
      this.$store.dispatch(AUTH_LOGOUT)
        .then(() => {
          if (this.$route.meta.hasOwnProperty('requiresAuth') && this.$route.meta.requiresAuth) {
            this.redirect({ name: 'home' })
          }

          this.closeMobileMenu()
        })
    },
    changeLanguage (language) {
      this.$store.dispatch(USER_CHANGE_LANGUAGE, language)
    },
    closeMobileMenu () {
      if (!this.showMobileMenu) return

      this.showMobileMenu = false
      this.showMobileUserInfo = false
      this.mobileMenuStep = 1
      this.selectedCategoryMobile = null
    },
    selectCategoryMobile (category) {
      if (!category.subCategories.length) {
        this.redirect({ name: 'shop.category', params: { categorySlug: this.$options.filters.st(category.slug) } })
        this.closeMobileMenu()
      } else {
        this.selectedCategoryMobile = category
        this.mobileMenuStep = 3
      }
    },
    showCategoryMenu (category) {
      if (category && category.hasOwnProperty('parentCategory')) {
        this.mobileMenuStep = 3
        this.selectedCategoryMobile = this.categories.find((cat) => cat.id === category.parentCategory.id)
      } else {
        this.mobileMenuStep = 2
      }

      this.showMobileMenu = true
    },
    showMobileMenuChanged (val) {
      if (val) {
        disableBodyScroll(this.$root.$el)
      } else {
        enableBodyScroll(this.$root.$el)
      }
    }
  }
}
</script>

<style scoped>
  .overlay-menu::before {
    position: fixed;
  }
</style>
