<template>
  <b-modal id="addFeedbackModal" ref="addFeedbackModal" dialog-class="custom-modal" :hide-footer="true">
    <template v-if="isLoggedIn">
      <div class="rating-stars">
        <span
          v-for="rank in 5"
          :key="rank"
          class="star-ico"
          :class="{ active: rank <= form.rank }"
          @click="selectRank(rank)"
        />
      </div>

      <div class="form-group text-center" :class="{ 'error': errors.has('rank') }">
        <small class="form-text">{{ errors.first('rank') }}</small>
      </div>

      <div class="title-modal">{{ $t('static_page.feedback.modal.title') }}</div>

      <textarea v-model="form.comment" class="review-message" />

      <input class="send-review" type="button" :value="$t('button.send_feedback')" @click.prevent="addFeedback">
    </template>
    <template v-else>
      <h3 class="text-center">{{ $t('static_page.feedback.warning.title') }}</h3>

      <div class="text-center mt-3">{{ $t('static_page.feedback.warning.message') }}</div>

      <input class="send-review mt-4" type="button" :value="$t('button.close')" @click.prevent="$refs.addFeedbackModal.hide()">
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { AUTH_IS_LOGGED_IN } from '@/store/types.getters'
import * as api from '@/api/general'

export default {
  name: 'AddFeedbackModal',
  data () {
    return {
      form: {
        rank: null,
        comment: null
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: AUTH_IS_LOGGED_IN
    })
  },
  methods: {
    selectRank (rank) {
      this.form.rank = rank
      this.$validator.errors.clear()
    },
    addFeedback () {
      if (this.form.rank === null) {
        this.$validator.errors.add({ field: 'rank', msg: 'Rank is required' })

        return
      }

      api.addFeedback(this.form)
        .then(() => this.$refs.addFeedbackModal.hide())
    }
  }
}
</script>
