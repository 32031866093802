export default [
  {
    path: 'question-answer',
    name: 'auth.question_answer.list',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/QuestionAnswerList')
  },
  {
    path: 'question-answer/:id(\\d+)',
    name: 'auth.question_answer.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/UpdateQuestionAnswer')
  },
  {
    path: 'question-answer/create',
    name: 'auth.question_answer.create',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/CreateQuestionAnswer')
  }
]
