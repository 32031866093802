<template>
  <div class="overlay-search open">
    <div class="search-mobile">
      <form v-on-clickaway="clickAway" @submit.prevent="search">
        <input type="submit">

        <input
          :value="searchQuery"
          type="text"
          :placeholder="$t('placeholder.search_product')"
          @input="({ target }) => searchQuery = target.value"
        >

        <input type="reset" @click.prevent="reset">
      </form>
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { mixin as clickaway } from 'vue-clickaway'
import { SHOP_SEARCH_QUERY } from '@/store/types.getters'
import { SHOP_SET_SEARCH_QUERY } from '@/store/types.mutations'

export default {
  name: 'HeaderSearchMobile',
  mixins: [clickaway],
  props: {
    showMobileSearch: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    searchQuery: {
      get () {
        return this.$store.getters[SHOP_SEARCH_QUERY]
      },
      set (value) {
        this.$store.commit(SHOP_SET_SEARCH_QUERY, value)
      }
    }
  },
  mounted () {
    disableBodyScroll(this.$root.$el)
  },
  beforeDestroy () {
    enableBodyScroll(this.$root.$el)
  },
  methods: {
    search () {
      this.$emit('close')
      this.redirect({ name: 'shop.search', query: { q: this.searchQuery } })
    },
    clickAway () {
      if (this.showMobileSearch) {
        this.$emit('close')
      }
    },
    reset () {
      if (this.searchQuery) {
        this.searchQuery = null
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>
