import axios from 'axios'
import store from '@/store'
import router from './../router'
import * as actionTypes from '@/store/types.actions'
import { AUTH_IS_LOGGED_IN, AUTH_REFRESH_TOKEN, AUTH_ACCESS_TOKEN, USER_LANGUAGE } from '@/store/types.getters'

let isRefreshing = false
let refreshSubscribers = []

export const authTokenFailInterceptor = [
  response => response,
  (error) => {
    const { config, response } = error
    const originalError = error
    const originalRequest = config

    if (!error.response) {
      // @TODO Toaster message
    }

    if (response && response.status === 401 && store.getters[AUTH_IS_LOGGED_IN]) {
      if (!isRefreshing && originalRequest.hasOwnProperty('afterRefresh') && originalRequest.afterRefresh) {
        store.dispatch(actionTypes.AUTH_LOGOUT)

        return Promise.reject(error)
      }

      if (!isRefreshing) {
        isRefreshing = true

        axios.post(
          process.env.VUE_APP_API_URL + '/v1/auth/refresh-token',
          { refreshToken: store.getters[AUTH_REFRESH_TOKEN] },
          { headers: { Authorization: null } }
        )
          .then(({ data }) => {
            store.dispatch(actionTypes.AUTH_AUTHENTICATE, data)
              .then(() => {
                isRefreshing = false

                refreshSubscribers.map(cb => cb(null, data.token))
                refreshSubscribers = []
              })
          })
          .catch(() => {
            isRefreshing = false

            refreshSubscribers.map(cb => cb(new Error('Unable to refresh access token')))
            refreshSubscribers = []

            store.dispatch(actionTypes.AUTH_LOGOUT, { forced: true })

            // @TODO redirect getting in consideration the locale
            router.push({ name: 'auth.login' })
          })
      }

      return new Promise((resolve, reject) => {
        refreshSubscribers.push((error, newToken) => {
          if (error) {
            return reject(originalError)
          }

          originalRequest.headers.Authorization = 'Bearer ' + newToken
          originalRequest.afterRefresh = true

          return resolve(axios(originalRequest))
        })
      })
    }

    return Promise.reject(error)
  }
]

export const authTokenInterceptor = [
  (config) => {
    if (!config.headers.common.Authorization && store.getters[AUTH_IS_LOGGED_IN]) {
      config.headers.common.Authorization = 'Bearer ' + store.getters[AUTH_ACCESS_TOKEN]
    }

    return config
  }
]

export const loaderRequestInterceptor = [
  (config) => {
    if (config.loader) {
      store.dispatch(actionTypes.GENERAL_INCREASE_LOADER)
    }

    return config
  },
  (error) => {
    if (error.config && error.config.loader) {
      store.dispatch(actionTypes.GENERAL_DECREASE_LOADER)
    }

    return Promise.reject(error)
  }
]

export const loaderResponseInterceptor = [
  (response) => {
    if (response && response.config && response.config.loader) {
      store.dispatch(actionTypes.GENERAL_DECREASE_LOADER)
    }

    return response
  },
  (error) => {
    if (error.config && error.config.loader) {
      store.dispatch(actionTypes.GENERAL_DECREASE_LOADER)
    }

    return Promise.reject(error)
  }
]

export const acceptLanguageRequestInterceptor = [
  (config) => {
    config.headers['Accept-Language'] = store.getters[USER_LANGUAGE]

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
]
