import * as api from '@/api/auth'
import * as actionTypes from '../types.actions'
import * as mutationTypes from '../types.mutations'
import * as getterTypes from '../types.getters'
import { parseJwt } from '@/services/jwt'

const state = {
  accessToken: null,
  refreshToken: null,
  roles: []
}

const getters = {
  [getterTypes.AUTH_IS_LOGGED_IN]: state => state.accessToken !== null,
  [getterTypes.AUTH_ACCESS_TOKEN]: state => state.accessToken,
  [getterTypes.AUTH_REFRESH_TOKEN]: state => state.refreshToken,
  [getterTypes.AUTH_ROLES]: state => state.roles,
  [getterTypes.AUTH_HAS_ADMIN_GROUP_ROLE]: (state) => {
    return state.roles[0] === 'ROLE_OPERATOR' ||
      state.roles[0] === 'ROLE_CONTENT_MANAGER' ||
      state.roles[0] === 'ROLE_ADMIN' ||
      state.roles[0] === 'ROLE_SUPER_ADMIN'
  },
  [getterTypes.AUTH_HAS_OPERATOR_GROUP_ROLE]: (state) => {
    return state.roles[0] === 'ROLE_OPERATOR' ||
    state.roles[0] === 'ROLE_ADMIN' ||
    state.roles[0] === 'ROLE_SUPER_ADMIN'
  },
  [getterTypes.AUTH_HAS_CONTENT_MANAGER_GROUP_ROLE]: (state) => {
    return state.roles[0] === 'ROLE_CONTENT_MANAGER' ||
      state.roles[0] === 'ROLE_ADMIN' ||
      state.roles[0] === 'ROLE_SUPER_ADMIN'
  }
}

const actions = {
  [actionTypes.AUTH_LOGIN] (context, payload) {
    return api.login(payload)
      .then(({ data }) => handleAuth(context, data))
  },
  [actionTypes.AUTH_AUTHENTICATE] (context, payload) {
    return handleAuth(context, payload)
  },
  [actionTypes.AUTH_LOGOUT] ({ commit, getters }, payload) {
    if (payload && payload.forced) {
      const tokenPayload = parseJwt(getters[getterTypes.AUTH_ACCESS_TOKEN])

      api.logout({
        username: tokenPayload ? tokenPayload.username : null,
        exp: tokenPayload ? tokenPayload.exp : null,
        refreshToken: getters[getterTypes.AUTH_REFRESH_TOKEN]
      })
    }

    commit(mutationTypes.ORDER_SET_DRAFT_TOKEN, null)
    commit(mutationTypes.ORDER_SET_DRAFT, null)
    commit(mutationTypes.ORDER_SET_DELIVERY_ADDRESSES, [])

    commit(mutationTypes.SHOP_SET_LAST_SEEN_PRODUCTS, [])

    commit(mutationTypes.USER_SET_PROFILE, null)

    return commit(mutationTypes.AUTH_RESET)
  },
  [actionTypes.AUTH_CONFIRM_EMAIL] (context, token) {
    return api.confirmEmail(null, token)
      .then(({ data }) => handleAuth(context, data))
  },
  [actionTypes.AUTH_RESET_PASSWORD] (context, payload) {
    return api.resetPassword(payload)
      .then(({ data }) => handleAuth(context, data))
  },
  [actionTypes.AUTH_GOOGLE] (context, payload) {
    return api.googleAuth(payload)
      .then(({ data }) => {
        handleAuth(context, data)
        return data
      })
  },
  [actionTypes.AUTH_FACEBOOK] (context, payload) {
    return api.facebookAuth(payload)
      .then(({ data }) => {
        handleAuth(context, data)
        return data
      })
  },
  [actionTypes.AUTH_VK] (context, payload) {
    return api.vkAuth(payload)
      .then(({ data }) => {
        handleAuth(context, data)
        return data
      })
  }
}

const mutations = {
  [mutationTypes.AUTH_SET_TOKENS] (state, payload) {
    state.accessToken = payload.accessToken
    state.refreshToken = payload.refreshToken
  },
  [mutationTypes.AUTH_RESET] (state) {
    state.accessToken = null
    state.refreshToken = null
    state.roles = []
  },
  [mutationTypes.AUTH_SET_ROLES] (state, roles) {
    state.roles = roles
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

function handleAuth (context, data) {
  context.commit(mutationTypes.AUTH_SET_TOKENS, { accessToken: data.token, refreshToken: data.refreshToken })

  context.commit(mutationTypes.AUTH_SET_ROLES, parseJwt(data.token).roles)

  context.dispatch(actionTypes.USER_FETCH_PROFILE)

  context.commit(mutationTypes.GENERAL_SET_FORCE_HIDE_FREE_BOTTLE_BANNER, false)

  if (context.getters[getterTypes.ORDER_DRAFT_TOKEN]) {
    context.dispatch(actionTypes.ORDER_LINK_DRAFT)
  }
}
