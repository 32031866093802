export default [
  {
    path: 'popups',
    name: 'auth.popup.list',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/PopupList')
  },
  {
    path: 'popup/:id(\\d+)',
    name: 'auth.popup.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/UpdatePopup')
  },
  {
    path: 'popup/create',
    name: 'auth.popup.create',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/platformContent/CreatePopup')
  }
]
