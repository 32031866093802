<template>
  <nav v-if="display" class="account-navs">
    <div class="width-locker">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-top">
            <li
              class="link-item user"
              :class="{ active: sanitizedRouteName === 'user.edit_profile' || sanitizedRouteName === 'user.change_password' }"
            >
              <t-router-link :to="{ name: 'user.edit_profile' }">
                {{ $t('header.user.profile') }}
              </t-router-link>
            </li>

            <li class="link-item order" :class="{ active: sanitizedRouteName === 'order.history' }">
              <t-router-link :to="{ name: 'order.history' }">
                {{ $t('header.user.order_history') }}
              </t-router-link>
            </li>

            <li class="link-item favorites" :class="{ active: sanitizedRouteName === 'user.favorite_products' }">
              <t-router-link :to="{ name: 'user.favorite_products' }">
                {{ $t('header.user.favorite_products') }}
              </t-router-link>
            </li>

            <li class="link-item logout">
              <a href="#" @click.prevent="logout()">{{ $t('auth.logout') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/types.actions'

export default {
  name: 'AccountNavs',
  computed: {
    display () {
      return this.sanitizedRouteName === 'user.favorite_products' ||
        this.sanitizedRouteName === 'user.change_password' ||
        this.sanitizedRouteName === 'user.edit_profile' ||
        this.sanitizedRouteName === 'order.history' ||
        this.sanitizedRouteName === 'order.create_delivery_address' ||
        this.sanitizedRouteName === 'order.change_delivery_address'
    }
  },
  methods: {
    logout () {
      this.$store.dispatch(AUTH_LOGOUT)
        .then(() => this.redirect({ name: 'home' }))
    }
  }
}
</script>
