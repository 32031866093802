import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'

export const responseDateTimeTransformer = (data) => {
  if (data instanceof Blob) {
    return data
  }

  try {
    if (typeof data !== 'object') {
      data = JSON.parse(data)
    }

    return recurseDateReplacer(data, (property) => new Date(property))
  } catch (e) {
    return data
  }
}

export const requestDateTimeTransformer = (data) => {
  try {
    if (typeof data === 'undefined' || data instanceof FormData) {
      return data
    }

    if (typeof data !== 'object') {
      data = JSON.parse(data)
    }

    return JSON.stringify(recurseDateReplacer(
      data,
      val => dayjs(val).format('YYYY-MM-DD[T]HH:mm:ss[P]'))
    )
  } catch (e) {
    return data
  }
}

function recurseDateReplacer (object, replacer) {
  const dateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?([+-][0-2]\d(:?[0-5]\d)?|Z)$/
  let result = object

  if (object != null) {
    result = cloneDeep(object)

    for (const key in result) {
      const property = result[key]

      if (typeof property === 'object') {
        result[key] = recurseDateReplacer(property)
      } else if (typeof property === 'string' && dateRegex.test(property)) {
        result[key] = replacer(property)
      }
    }
  }

  return result
}
