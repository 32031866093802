<template>
  <fragment>
    <t-router-link :to="{ name: 'order.cart' }" class="cart-link">
      {{ $t('cart.title') }}
      <span class="cart-count">
        ({{ draftOrder ? draftOrder.orderProducts.length : 0 }})
      </span>

      <span class="total-price">
        {{ draftOrder ? draftOrder.totalPrice : 0 }} {{ $t('currency.lei') }}
      </span>

      <span class="product-count">
        {{ draftOrder ? draftOrder.orderProducts.length : 0 }}
      </span>
    </t-router-link>

    <div class="cart-dropdown">
      <ul v-if="draftOrder">
        <HeaderOrderItem
          v-for="orderProduct in draftOrder.orderProducts"
          :key="orderProduct.id"
          :order-product="orderProduct"
        />
      </ul>
      <div v-else class="text-center mt-4">
        {{ $t('cart.empty') }}
      </div>

      <div class="nav-cart">
        <t-router-link :to="{ name: 'order.cart' }" class="view-cart">{{ $t('button.view_cart') }}</t-router-link>
        <t-router-link :to="{ name: 'order.cart', params: { 'toSecondStep': true } }" class="get-order">{{ $t('button.checkout') }}</t-router-link>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORDER_DRAFT } from '@/store/types.getters'
import HeaderOrderItem from '@/components/layout/home-layout/HeaderOrderItem'

export default {
  name: 'HeaderOrderInfo',
  components: { HeaderOrderItem },
  computed: {
    ...mapGetters({
      draftOrder: ORDER_DRAFT
    })
  }
}
</script>
