<template>
  <nav v-if="show" class="breadcrumbs-top" aria-label="breadcrumb">
    <div class="width-locker">
      <div class="row">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <t-router-link :to="{ name: 'home' }">{{ $t('home.name') }}</t-router-link>
            </li>

            <li class="breadcrumb-item">
              <t-router-link :to="{ name: 'shop.main' }">
                {{ $t('shop') }}
              </t-router-link>
            </li>

            <li v-if="category && category.parentCategory" class="breadcrumb-item">
              <t-router-link :to="{ name: 'shop.category', params: { categorySlug: $options.filters.st(category.parentCategory.slug) } }">
                {{ category.parentCategory.name|st }}
              </t-router-link>
            </li>

            <li v-if="category" class="breadcrumb-item">
              <t-router-link :to="{ name: 'shop.category', params: { categorySlug: $options.filters.st(category.slug) } }">
                {{ category.name|st }}
              </t-router-link>
            </li>

            <li v-if="isProductRoute && product" class="breadcrumb-item active" aria-current="page">{{ product.name|st }}</li>
            <li v-else class="breadcrumb-item active" aria-current="page">{{ $t('product.all') }}</li>
          </ol>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { SHOP_CATEGORY, SHOP_PRODUCT } from '@/store/types.getters'

export default {
  name: 'Breadcrumbs',
  computed: {
    ...mapGetters({
      category: SHOP_CATEGORY,
      product: SHOP_PRODUCT
    }),
    show () {
      return this.isCategoryRoute || this.isProductRoute
    },
    isCategoryRoute () {
      return this.sanitizedRouteName === 'shop.main' ||
        this.sanitizedRouteName === 'shop.category'
    },
    isProductRoute () {
      return this.sanitizedRouteName === 'shop.product'
    }
  }
}
</script>
