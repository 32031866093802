import Vue from 'vue'
import Router from 'vue-router'
import VueBodyClass from 'vue-body-class'
import Auth from './auth'
import PlatformContent from './platform-content'
import User from './user'
import Shop from './shop'
import Order from './order'
import Admin from './admin'
import CommonLayout from '@/components/layout/CommonLayout'
import Home from '@/views/Home'
import { authGuard, localeGuard } from '@/services/router-guards'
import { translateRoutes } from '@/services/router-helpers'
import Webvisor from "@/components/layout/Webvisor.vue";
import WebvisorHomeLayout from "@/components/layout/WebvisorHomeLayout.vue";

Vue.use(Router)

const routes = [
  // {
  //   path: '/home-webvisor',
  //   component: WebvisorHomeLayout, // Используем новый лэйаут
  //   children: [
  //     {
  //       path: '',
  //       name: 'webvisor',
  //       component: Webvisor
  //     }
  //   ]
  // },
  {
    path: '',
    component: CommonLayout,
    children: [
      ...translateRoutes(Auth),
      {
        path: '',
        component: () => import(/* webpackPreload: true */ '@/components/layout/HomeLayout'),
        children: translateRoutes([
          ...PlatformContent,
          ...Shop,
          ...User,
          ...Order,
          {
            path: '',
            name: 'home',
            component: Home
          }
        ])
      },
      ...Admin,
      {
        path: '',
        component: () => import(/* webpackPreload: true */ '@/components/layout/HomeLayout'),
        children: translateRoutes([
          {
            path: '*',
            name: 'error.404',
            component: () => import('../views/error/NotFound')
          }
        ])
      }
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: routes
})

const vueBodyClass = new VueBodyClass(routes)
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) })
router.beforeEach(localeGuard)
router.beforeEach(authGuard)

export default router
