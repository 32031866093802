import * as api from '@/api/general'
import * as actionTypes from '@/store/types.actions'
import * as getterTypes from '@/store/types.getters'
import {
  GENERAL_CHANGE_LOADER,
  GENERAL_SET_BANNERS,
  GENERAL_SET_META,
  GENERAL_SET_CITIES,
  GENERAL_SET_CURRENT_CITY_ID,
  GENERAL_SET_FORCE_HIDE_FREE_BOTTLE_BANNER,
  GENERAL_SHOW_POPUP
} from '@/store/types.mutations'

const state = {
  loader: 0,
  banners: [],
  meta: [],
  cities: [],
  currentCityId: null,
  forceHideFreeBottleBanner: false,
  showPopup: null,
}

const getters = {
  [getterTypes.SHOW_POPUP]: state => state.showPopup,

  [getterTypes.GENERAL_IS_ACTIVE_LOADER]: state => state.loader > 0,
  [getterTypes.GENERAL_BANNER_BY_POSITION]: (state) => (position) => state.banners.find((banner) => banner.position === position),
  [getterTypes.GENERAL_META_BY_KEY]: (state) => (key) => state.meta.find((meta) => meta.key === key),
  [getterTypes.GENERAL_CITIES]: state => state.cities,
  [getterTypes.GENERAL_CURRENT_CITY]: (state) => {
    if (state.currentCityId) {
      const currentCity = state.cities.find(city => city.id === state.currentCityId)

      if (currentCity) {
        return currentCity
      }
    }

    return state.cities.find(city => city.default)
  },
  [getterTypes.GENERAL_CURRENT_CITY_ID]: (state, getters) => {
    const currentCity = getters[getterTypes.GENERAL_CURRENT_CITY]

    return currentCity ? currentCity.id : undefined
  },
  [getterTypes.GENERAL_CITY_BY_ID]: state => id => {
    return state.cities.find(city => city.id === id)
  },
  [getterTypes.GENERAL_FORCE_HIDE_FREE_BOTTLE_BANNER]: state => state.forceHideFreeBottleBanner
}

const actions = {
  [actionTypes.GENERAL_SHOW_POPUP] ({ commit }, value) {
    return commit(GENERAL_SHOW_POPUP, value)
  },
  [actionTypes.GENERAL_INCREASE_LOADER] ({ commit }) {
    return commit(GENERAL_CHANGE_LOADER, 1)
  },
  [actionTypes.GENERAL_DECREASE_LOADER] ({ commit }) {
    return commit(GENERAL_CHANGE_LOADER, -1)
  },
  [actionTypes.GENERAL_FETCH_BANNERS] ({ commit }) {
    return api.banners()
      .then(({ data }) => commit(GENERAL_SET_BANNERS, data._embedded.items))
  },
  [actionTypes.GENERAL_FETCH_META] ({ commit }) {
    return api.meta()
      .then(({ data }) => commit(GENERAL_SET_META, data._embedded.items))
  },
  [actionTypes.GENERAL_FETCH_CITIES] ({ commit }) {
    return api.cities()
      .then(({ data }) => commit(GENERAL_SET_CITIES, data._embedded.items))
  },
  [actionTypes.GENERAL_CHANGE_CURRENT_CITY] ({ commit, getters, dispatch }, cityId) {
    commit(GENERAL_SET_CURRENT_CITY_ID, cityId)

    if (getters[getterTypes.AUTH_IS_LOGGED_IN]) {
      return dispatch(actionTypes.USER_UPDATE_CITY, cityId)
    }

    return Promise.resolve()
  }
}

const mutations = {
  [GENERAL_SHOW_POPUP] (state, value) {
    state.showPopup = value
  },
  [GENERAL_CHANGE_LOADER] (state, modifier) {
    state.loader += modifier
  },
  [GENERAL_SET_BANNERS] (state, banners) {
    state.banners = banners
  },
  [GENERAL_SET_META] (state, meta) {
    state.meta = meta
  },
  [GENERAL_SET_CITIES] (state, cities) {
    state.cities = cities
  },
  [GENERAL_SET_CURRENT_CITY_ID] (state, cityId) {
    state.currentCityId = cityId
  },
  [GENERAL_SET_FORCE_HIDE_FREE_BOTTLE_BANNER] (state, forceHideFreeBottleBanner) {
    state.forceHideFreeBottleBanner = forceHideFreeBottleBanner
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
