export default [
  {
    path: 'shop',
    name: 'shop.main',
    component: () => import(/* webpackChunkName: "shop" */ '../views/shop/Shop')
  },
  {
    path: 'shop-all',
    name: 'shop.all',
    props: true,
    component: () => import(/* webpackChunkName: "shop" */ '../views/shop/Catalog')
  },
  {
    path: 'shop/:categorySlug',
    name: 'shop.category',
    props: true,
    component: () => import(/* webpackChunkName: "shop" */ '../views/shop/Catalog')
  },
  {
    path: 'shop/:categorySlug?/:productSlug',
    name: 'shop.product',
    props: true,
    component: () => import(/* webpackChunkName: "shop" */ '../views/shop/Product')
  },
  {
    path: 'search',
    name: 'shop.search',
    props: (route) => ({ page: route.query.page ? parseInt(route.query.page) : undefined }),
    component: () => import(/* webpackChunkName: "shop" */ '../views/shop/Search')
  }
]
