<template>
  <div v-if="waterBottleProducts.length" class="water">
    <ul>
      <li>
        <div class="title">{{ $t('product.water_bottle.title') }}</div>
        <div class="desc">{{ $t('product.water_bottle.description') }}</div>
      </li>

      <WaterBottlesProduct
        v-for="product in waterBottleProducts"
        :key="product.id"
        :product="product"
      />
    </ul>
  </div>
</template>

<script>
import { SHOP_FETCH_PRODUCTS } from '@/store/types.actions'
import WaterBottlesProduct from '@/components/shop/products-block/WaterBottlesProduct'
import { BOTTLES_CATEGORY_SLUG } from '@/services/const/shop'

export default {
  name: 'WaterBottles',
  components: { WaterBottlesProduct },
  data () {
    return {
      waterBottleProducts: []
    }
  },
  created () {
    this.$store.dispatch(SHOP_FETCH_PRODUCTS, { category_slug: BOTTLES_CATEGORY_SLUG, per_page: 3 })
      .then(({ data }) => { this.waterBottleProducts = data._embedded.items })
  }
}
</script>
