import Vue from 'vue'
import VeeValidate from 'vee-validate'
import validationMessagesRo from '@/lang/validation/ro'
import validationMessagesRu from '@/lang/validation/ru'

Vue.use(VeeValidate, {
  locale: 'ru',
  events: 'change',
  fieldsBagName: 'veeFields',
  dictionary: {
    ro: validationMessagesRo,
    ru: validationMessagesRu
  }
})
