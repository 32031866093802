export default [
  {
    path: 'products',
    name: 'auth.product.list',
    component: () => import(/* webpackChunkName: "admin-product" */ '../../views/admin/shop/ProductList')
  },
  {
    path: 'product/:id(\\d+)',
    name: 'auth.product.update',
    props: true,
    component: () => import(/* webpackChunkName: "admin-product" */ '../../views/admin/shop/UpdateProduct')
  },
  {
    path: 'product/create',
    name: 'auth.product.create',
    component: () => import(/* webpackChunkName: "admin-product" */ '../../views/admin/shop/CreateProduct')
  },
  {
    path: 'missing-barcodes',
    name: 'auth.missing_barcode.list',
    component: () => import(/* webpackChunkName: "admin-product" */ '../../views/admin/shop/MissingBarcodeList')
  }
]
