<template>
  <li>
    <div class="img-product">
      <img :src="product.coverImage.absolutePath">
    </div>

    <div class="search-result">
      <ProductLink :product="product" class="search-title" />

      <div class="price">{{ product.price }} {{ $t('currency.lei') }}</div>
    </div>

    <div v-if="product.inStock" class="add-to-cart">
      <div class="count-product">
        <span class="remove" @click="quantity = Math.max(parseInt(quantity) - 1, 1)" />

        <input v-model="quantity" type="text">

        <div class="add-product" @click="quantity = parseInt(quantity) + 1" />
      </div>

      <a class="add-products" href="#" @click.prevent="addProductToOrder({ product: product, quantity: quantity })" />
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import { ORDER_ADD_PRODUCT } from '@/store/types.actions'

export default {
  name: 'HeaderSearchProduct',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      quantity: 1
    }
  },
  methods: {
    ...mapActions({
      addProductToOrder: ORDER_ADD_PRODUCT
    })
  }
}
</script>
