import { translateRoutes } from '@/services/router-helpers'
import AdminUser from '@/router/admin/user'
import AdminFeedback from '@/router/admin/feedback'
import AdminOrder from '@/router/admin/order'
import AdminCategory from '@/router/admin/category'
import AdminProduct from '@/router/admin/product'
import AdminCharacteristicField from '@/router/admin/characteristicField'
import AdminBanner from '@/router/admin/banner'
import AdminCarousel from '@/router/admin/carousel'
import AdminNews from '@/router/admin/news'
import AdminStaticPage from '@/router/admin/staticPage'
import AdminQuestionAnswer from '@/router/admin/questionAnswer'
import AdminBlockedDeliveryDate from '@/router/admin/blockedDeliveryDate'
import AdminPopup from '@/router/admin/popup'

import { adminGuard } from '@/services/router-guards'

export default [
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin-general" */ '../../components/layout/AdminLayout'),
    meta: { bodyClass: 'hold-transition skin-blue sidebar-mini' },
    children: translateRoutes([
      ...AdminUser,
      ...AdminFeedback,
      ...AdminOrder,
      ...AdminCategory,
      ...AdminProduct,
      ...AdminCharacteristicField,
      ...AdminBanner,
      ...AdminCarousel,
      ...AdminNews,
      ...AdminStaticPage,
      ...AdminQuestionAnswer,
      ...AdminBlockedDeliveryDate,
      ...AdminPopup,
      {
        path: '',
        name: 'admin.dashboard',
        component: () => import(/* webpackChunkName: "admin-general" */ '../../views/admin/Dashboard')
      }
    ]),
    beforeEnter: adminGuard
  }
]
