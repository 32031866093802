import axios from 'axios'
import axiosCancel from 'axios-cancel'
import {
  responseDateTimeTransformer,
  requestDateTimeTransformer
} from '@/services/axios-transformers'
import {
  authTokenFailInterceptor,
  authTokenInterceptor,
  loaderRequestInterceptor,
  loaderResponseInterceptor,
  acceptLanguageRequestInterceptor
} from '@/services/axios-interceptors'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  transformResponse: { responseDateTimeTransformer: responseDateTimeTransformer },
  transformRequest: { requestDateTimeTransformer: requestDateTimeTransformer }
})

axiosCancel(axiosInstance, {
  debug: false
})

axiosInstance.interceptors.response.use(...authTokenFailInterceptor)
axiosInstance.interceptors.request.use(...authTokenInterceptor)
axiosInstance.interceptors.request.use(...loaderRequestInterceptor)
axiosInstance.interceptors.response.use(...loaderResponseInterceptor)
axiosInstance.interceptors.request.use(...acceptLanguageRequestInterceptor)

export default axiosInstance
