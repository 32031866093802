<template>
  <li>
    <img :src="product.coverImage.absolutePath">

    <div class="mobile-wrap">
      <div class="title">
        <ProductLink :product="product" />
      </div>

      <div class="price">{{ product.price }} {{ $t('currency.lei') }}</div>

      <div v-if="product.discount" class="old-price">{{ product.originalPrice }} {{ $t('currency.lei') }}</div>

      <div class="add-to-cart">
        <div class="count-product">
          <span class="remove" @click="quantity = Math.max(parseInt(quantity) - 1, 1)" />

          <input v-model="quantity" type="text">

          <div class="add-product" @click="quantity = parseInt(quantity) + 1" />
        </div>

        <a class="add-products" href="#" @click.prevent="addProductToOrder({ product: product, quantity: quantity })" />
      </div>
    </div>
  </li>
</template>

<script>
import { mapActions } from 'vuex'
import { ORDER_ADD_PRODUCT } from '@/store/types.actions'

export default {
  name: 'WaterBottlesProduct',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      quantity: 1
    }
  },
  methods: {
    ...mapActions({
      addProductToOrder: ORDER_ADD_PRODUCT
    })
  }
}
</script>

<style scoped lang="scss">
.old-price {
  color: #f24b1e;
  font-size: 17px;
  display: inline-block;
  padding: 0 5px;
  position: relative;
  font-family: 'Akrobat';
  font-weight: 700;

  &::before {
    content: '';
    position: absolute;
    height: 2px;
    background: #f24b1e;
    left: 0;
    right: 0;
    top: 12px;
  }
}

@media screen and (min-width: 1170px) {
  li {
    height: 440px;
  }

  img {
    width: auto;
    height: 196px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1169px)  {
  img {
    width: 135px;
    height: auto;
  }
}
</style>
