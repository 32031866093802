<template>
  <router-view />
</template>

<style lang="scss">
@import 'assets/styles/main';
</style>
<script>

export default {
  mounted () {
    const siteUrl = (process.env.VUE_APP_BASE_URL || 'https://pelican.md').replace(/\/+$/, '/')

    const ensureTrailingSlash = (url) => (url.endsWith('/') ? url : `${url}/`)

    const getLocalizedUrl = (path, lang) => {
      if (path.startsWith('/ru')) return ensureTrailingSlash(lang === 'ro' ? path.replace('/ru', '') : path)
      if (path.startsWith('/ro')) return ensureTrailingSlash(lang === 'ro' ? path : `/ru${path.replace('/ro', '')}`)
      return ensureTrailingSlash(lang === 'ro' ? path : `/ru${path}`)
    }

    const generateMetaTags = (path) => [
      { hreflang: 'ro', href: `${siteUrl}${getLocalizedUrl(path, 'ro')}` },
      { hreflang: 'ru', href: `${siteUrl}${getLocalizedUrl(path, 'ru')}` },
      { hreflang: 'x-default', href: `${siteUrl}${getLocalizedUrl(path, 'ro')}` }
    ]

    const updateMetaTags = (path) => {
      document.querySelectorAll('link[rel="alternate"]').forEach((link) => link.remove())
      generateMetaTags(path).forEach(({ hreflang, href }) => {
        const link = Object.assign(document.createElement('link'), { rel: 'alternate', hreflang, href })
        document.head.appendChild(link)
      })
    }

    updateMetaTags(window.location.pathname)
    this.$router.afterEach(({ path }) => updateMetaTags(path))
  }
}
</script>
