<template>
  <div class="wrapper">
    <Header />

    <Breadcrumbs />
    <AccountNavs />

    <main class="page-content">
      <router-view :key="$route.name + ($route.params.productSlug || '') + ($route.params.slug || '') + ($route.params.categorySlug || '')" />
    </main>

    <SeoBlock v-if="displaySeoBlock" :text="seoText" />

    <Footer />

    <AddToCardMobile v-if="$mq !== 'xl'" />

    <CitySelectorModal />

    <Popup ref="popup"/>
  </div>
</template>

<script>
import {SHOP_FETCH_CATEGORIES, ORDER_FETCH_DRAFT, GENERAL_SHOW_POPUP} from '@/store/types.actions'
import Header from '@/components/layout/home-layout/Header'
import Footer from '@/components/layout/home-layout/Footer'
import Breadcrumbs from '@/components/layout/home-layout/Breadcrumbs'
import AccountNavs from '@/components/layout/home-layout/AccountNavs'
import AddToCardMobile from '@/components/shop/mobile/AddToCardMobile'
import CitySelectorModal from '@/components/user/CitySelectorModal'
import SeoBlock from '@/components/layout/home-layout/SeoBlock'
import { GENERAL_META_BY_KEY, SHOP_CATEGORY } from '@/store/types.getters'
import Popup from "@/components/general/Popup.vue";
import {popups} from "@/api/general";

export default {
  name: 'HomeLayout',
  components: {
    Popup,
    SeoBlock,
    CitySelectorModal,
    AddToCardMobile,
    AccountNavs,
    Breadcrumbs,
    Footer,
    Header
  },
  computed: {
    displaySeoBlock () {
      const routes = [
        'home',
        'shop.main',
        'shop.category'
      ]

      return this.$route && routes.includes(this.sanitizedRouteName)
    },
    seoText () {
      let seoText
      const sanitizedRouteName = this.sanitizedRouteName

      if (sanitizedRouteName === 'home' || sanitizedRouteName === 'shop.main') {
        const metaInfo = this.$store.getters[GENERAL_META_BY_KEY](sanitizedRouteName)
        seoText = metaInfo ? this.$options.filters.st(metaInfo.seoText) : null
      } else if (sanitizedRouteName === 'shop.category') {
        const category = this.$store.getters[SHOP_CATEGORY]
        seoText = category ? this.$options.filters.st(category.seoText) : null
      }

      return seoText
    }
  },
  created () {
    this.$store.dispatch(SHOP_FETCH_CATEGORIES)
    this.$store.dispatch(ORDER_FETCH_DRAFT)
  },
  watch: {
    '$store.getters.GENERAL_IS_ACTIVE_LOADER': {
      immediate: true,
      handler(value)  {
        if (!value ) {
          this.fetchPopup()
        }
      }
    }
  },
  methods: {
    async fetchPopup () {
      const url = window.location.href;
      const response = await popups({url: url});
      const popup = response.data._embedded.items[0]

      if (popup) {
        await this.$store.dispatch(GENERAL_SHOW_POPUP, popup)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/styles/client';
</style>
