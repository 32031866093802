import { postLoaderRequest, patchLoaderRequest, getLoaderRequest, postRequest } from '@/services/requests'

export const login = postLoaderRequest('/v1/auth/login')
export const register = postLoaderRequest('/v1/auth/register')
export const confirmEmail = patchLoaderRequest('/v1/auth/confirm-email/:token')
export const requestResetPassword = postLoaderRequest('/v1/auth/request-reset-password')
export const checkResetPassword = getLoaderRequest('/v1/auth/check-reset-password')
export const resetPassword = postLoaderRequest('/v1/auth/reset-password')
export const changePassword = patchLoaderRequest('/v1/auth/change-password')
export const googleAuth = postLoaderRequest('/v1/auth/login/google')
export const facebookAuth = postLoaderRequest('/v1/auth/login/facebook')
export const vkAuth = postLoaderRequest('/v1/auth/login/vk')
export const logout = postRequest('/v1/auth/logout')
