export const translateRoutes = (routes) => {
  const translatedRoutes = []

  routes.forEach((route) => {
    ['ro', 'ru', 'en'].forEach((language) => {
      const translatedRoute = Object.assign({}, route)

      if (typeof translatedRoute.path === 'object') {
        translatedRoute.path = translatedRoute.path[language]
      }

      if (language !== 'ro') {
        translatedRoute.path = language + '/' + translatedRoute.path
        translatedRoute.name = language + '.' + translatedRoute.name
      }

      translatedRoutes.push(translatedRoute)
    })
  })

  return translatedRoutes
}
