<template>
  <div>
    <form class="search-form" @submit.prevent="search">
      <input
        :value="searchQuery"
        type="text"
        :placeholder="$t('placeholder.search_product_by_name')"
        @input="setSearchQuery"
      >

      <button type="submit" />
    </form>

    <div v-on-clickaway="resetSearch" :class="{ opened: items.length > 0 }" class="search-results">
      <ul>
        <HeaderSearchProduct v-for="product in items" :key="product.id" :product="product" />
      </ul>

      <div class="view-all-results">
        <a href="#" @click.prevent="search" v-html="$t('search.results_count', [ total ])" />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import { search } from '@/api/shop'
import axios from '@/setup/axios'
import HeaderSearchProduct from '@/components/layout/home-layout/HeaderSearchProduct'
import { SHOP_SEARCH_QUERY, GENERAL_CURRENT_CITY_ID } from '@/store/types.getters'
import { SHOP_SET_SEARCH_QUERY } from '@/store/types.mutations'

export default {
  name: 'HeaderSearch',
  components: { HeaderSearchProduct },
  mixins: [clickaway],
  data () {
    return {
      items: [],
      total: 0
    }
  },
  computed: {
    ...mapGetters({
      searchQuery: SHOP_SEARCH_QUERY
    })
  },
  created () {
    if (this.$route.query.q) {
      this.$store.commit(SHOP_SET_SEARCH_QUERY, this.$route.query.q)
    }
  },
  methods: {
    setSearchQuery ({ target }) {
      this.$store.commit(SHOP_SET_SEARCH_QUERY, target.value)

      this.liveSearch()
    },
    liveSearch () {
      const requestId = 'search_request'

      axios.cancel(requestId)

      search({ q: this.searchQuery, per_page: 3, city_id: this.$store.getters[GENERAL_CURRENT_CITY_ID] }, null, requestId)
        .then(({ data }) => {
          this.items = data._embedded.items
          this.total = data.total
        })
        .catch(() => {
          // request canceled
        })
    },
    search () {
      this.redirect({ name: 'shop.search', query: { q: this.searchQuery } })
      this.resetSearch()
    },
    resetSearch () {
      this.items = []
    }
  }
}
</script>
