<template>
  <fragment>
    <div v-if="feedbacks.length" class="slider-bottom">
      <div class="customer-reviews">
        <div class="title">{{ $t('static_page.feedback.title') }}</div>

        <t-router-link :to="{ name: 'platform_content.feedbacks' }" class="all-reviews">
          {{ $t('static_page.feedback.list') }}
        </t-router-link>

        <a v-b-modal.addFeedbackModal class="add-review" href="#" @click.prevent="">{{ $t('static_page.feedback.add') }}</a>
      </div>

      <div class="inner-slider feedback-slider">
        <swiper :options="feedbacksSlidesOptions" class="swiper-bottom-slider">
          <swiper-slide v-if="$mq === 'sm'" class="customer-reviews">
            <div class="title">{{ $t('static_page.feedback.title') }}</div>

            <t-router-link :to="{ name: 'platform_content.feedbacks' }" class="all-reviews">
              {{ $t('static_page.feedback.list') }}
            </t-router-link>

            <a v-b-modal.addFeedbackModal class="add-review" href="#" @click.prevent="">{{ $t('static_page.feedback.add') }}</a>
          </swiper-slide>

          <swiper-slide v-for="feedback in feedbacks" :key="feedback.id">
            <span class="star">{{ feedback.rank }}</span>

            <div class="comment-author">{{ feedback.comment }}</div>

            <div class="author">- {{ feedback.user.firstName }} {{ feedback.user.lastName }}</div>
          </swiper-slide>

          <div slot="button-prev" class="bottom-slider-button-prev" />
          <div slot="button-next" class="bottom-slider-button-next" />
        </swiper>
      </div>
    </div>

    <AddFeedbackModal />
  </fragment>
</template>

<script>
import AddFeedbackModal from '@/components/static-pages/feedback/AddFeedbackModal'
import * as api from '@/api/general'

export default {
  name: 'FeedbacksSlider',
  components: { AddFeedbackModal },
  data () {
    return {
      feedbacks: [],
      feedbacksSlidesOptions: {
        slidesPerView: 'auto',
        spaceBetween: 30,
        navigation: { nextEl: '.bottom-slider-button-next', prevEl: '.bottom-slider-button-prev' },
        breakpoints: {
          1920: { spaceBetween: 30, width: 255 },
          1500: { spaceBetween: 30, width: 255 },
          1024: { spaceBetween: 30, width: 255 },
          768: { spaceBetween: 30, width: 255 },
          640: { spaceBetween: 20, width: 255 },
          500: { spaceBetween: 15, width: 255 }
        }
      }
    }
  },
  async created () {
    const response = await api.feedbacks()

    this.feedbacks = response.data._embedded.items
  }
}
</script>

<style scoped>
  .swiper-bottom-slider {
    overflow: visible;
  }
</style>
